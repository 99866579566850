import { UserRole } from 'shared/constants';
import ApiService from 'shared/services/api.service';
import ToastService from 'shared/services/toast.service';
import { persistor } from 'shared/store';

import { signOut } from '../store/actions/auth.actions';

import type {
	AuthenticationResponse,
	LoginFormValues,
	RestorePasswordRequest,
	SignupFormValues,
	SignupResponse,
	VerificationRequest,
	VerificationResponse,
} from '../types';

const api = ApiService.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<AuthenticationResponse, LoginFormValues>({
			query: (credentials) => ({
				url: 'auth',
				method: 'POST',
				body: credentials,
				params: {
					role: UserRole.COACH,
				},
			}),
		}),
		signup: builder.mutation<SignupResponse, SignupFormValues>({
			query: (credentials) => ({
				url: 'auth/coach/register',
				method: 'POST',
				body: credentials,
			}),
		}),
		verificationKey: builder.mutation<VerificationResponse, VerificationRequest>({
			query: ({ otp, tokenId }) => ({
				url: 'auth/coach/verify',
				method: 'POST',
				params: {
					role: UserRole.COACH,
				},
				body: {
					otp,
					tokenId,
				},
			}),
		}),
		forgotPassword: builder.mutation<void, string>({
			query: (email) => ({
				url: 'auth/forgot-password',
				method: 'POST',
				params: {
					role: UserRole.COACH,
				},
				body: {
					email,
				},
			}),
		}),
		restorePassword: builder.mutation<void, RestorePasswordRequest>({
			query: ({ password, tokenId }) => ({
				url: 'auth/forgot-password',
				method: 'POST',
				params: {
					role: UserRole.COACH,
				},
				body: {
					tokenId,
					password,
				},
			}),
		}),
		logout: builder.query<void, void>({
			query: () => ({
				url: 'auth/logout',
				method: 'POST',
			}),
			extraOptions: {
				maxRetries: 0,
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				ToastService.closeAll();
				//dispatch at the end of the stack to allow RTK Query to get the authorization token
				setTimeout(() => {
					dispatch(signOut());
					persistor.persist();
				});
				await Promise.allSettled([persistor.flush(), window.sessionStorage.clear(), queryFulfilled, window.localStorage.clear()]);
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useLoginMutation,
	useSignupMutation,
	useVerificationKeyMutation,
	useForgotPasswordMutation,
	useRestorePasswordMutation,
	useLazyLogoutQuery,
} = api;
