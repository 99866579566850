/**
 *
 * @description polyfill allSettled
 *  @see http://medium.com/@95yashsharma/polyfill-for-promise-allsettled-965f9f2a003
 */
if (!Promise.allSettled)
	//@ts-ignore polyfill Promise allSettled
	Promise.allSettled = function (promises: Array<Promise<unknown>>) {
		return Promise.all(
			promises.map((p: Promise<unknown> | undefined) => {
				if (!p) {
					console.warn('WARN: invalid promise in Promise.allSettled');
				}
				return (p || Promise.resolve(p))
					.then((value: unknown) => {
						return {
							status: 'fulfilled',
							value,
						};
					})
					.catch((reason: unknown) => {
						return {
							status: 'rejected',
							reason,
						};
					});
			}),
		);
	};

export {};
