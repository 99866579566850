import React from 'react';
import { Box, useBreakpointValue, useDisclosure } from '@chakra-ui/react';

import Header from 'routes/components/Header';
import Sidebar from 'routes/components/Sidebar';
import { mdVariant, SIDE_BAR_WIDTH, smVariant } from 'routes/constants';
import PageLoading from 'shared/components/PageLoading';

type Props = {
	children?: React.ReactNode;
};

function MainLayout({ children }: Props) {
	const variants = useBreakpointValue({ base: smVariant, md: mdVariant });
	const { isOpen, onClose, onToggle } = useDisclosure();

	return (
		<>
			<Sidebar variant={variants?.navigation} isOpen={isOpen} onClose={onClose} />
			<Box ml={!variants?.navigationButton ? SIDE_BAR_WIDTH : undefined} bg="#FFFFFF" minH="100vh" overflowX="hidden">
				<Header showSidebarButton={variants?.navigationButton} onShowSidebar={onToggle} />
				<Box as="main" px={[4, 6, 8]} py={[6, 8, 10]}>
					<React.Suspense fallback={<PageLoading />}>{children}</React.Suspense>
				</Box>
			</Box>
		</>
	);
}

export default MainLayout;
