import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { selectUserRole, selectUserToken } from 'features/auth/store/selectors';
import { routes } from 'routes/constants';
import { UserRole } from 'shared/constants';
import { useAppSelector } from 'shared/store/hooks';

type Props = React.ComponentPropsWithoutRef<typeof Route> & {
	validOnlyFor?: UserRole;
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, component: Component, validOnlyFor, ...rest }: Props) {
	const userToken = useAppSelector(selectUserToken);
	const role = useAppSelector(selectUserRole);
	const isValidAccess = Boolean(validOnlyFor ? userToken && role === validOnlyFor : userToken);

	return (
		<Route
			{...rest}
			render={(props) =>
				isValidAccess ? (
					Component ? (
						<Component {...props} />
					) : (
						children
					)
				) : (
					<Redirect
						to={{
							pathname: `/${routes.login}`,
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
}

export default PrivateRoute;
