import React from 'react';
import { Tabs, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TopNavBar from 'routes/components/TopNavBar';
import { routes } from 'routes/constants';

import NewCoachComponent from '../components/NewCoach.component';
import { forms } from '../constants';
import { useCreateCoachMutation } from '../services/coaches.service';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { NewCoachFormValues } from '../types';

function NewCoachContainer() {
	const toast = useToast();
	const [createCoach, { isError, error, isLoading }] = useCreateCoachMutation();
	const {
		handleSubmit,
		control,
		formState: { isSubmitted, isSubmitting },
	} = useForm<NewCoachFormValues>({
		defaultValues: forms.newCoach.initialValues,
		resolver: yupResolver(forms.newCoach.schema),
		mode: 'onBlur',
	});

	const onSubmit = async (values: NewCoachFormValues) => {
		const data = {
			...values,
			password: values.phone,
		};
		try {
			await createCoach(data).unwrap();
		} catch (error) {
			toast.closeAll();
			throw error;
		}
	};

	return (
		<Tabs>
			<TopNavBar
				title="Add New Coach"
				goBackTo={`/${routes.coaches}`}
				tabs={[{ label: 'All Coaches', to: `/${routes.coaches}` }]}
			/>
			<form onSubmit={handleSubmit(onSubmit)}>
				<NewCoachComponent
					control={control}
					errorMessage={
						isError && isSubmitted && !!error && !isSubmitting && !isLoading
							? ((error as FetchBaseQueryError)?.data as Error)?.message || 'Coach details are incorrect'
							: undefined
					}
				/>
			</form>
		</Tabs>
	);
}

export default NewCoachContainer;
