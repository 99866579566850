import React from 'react';
import { Icon } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import UilAngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';

import type { LocationDescriptor } from 'history';

type Props = {
	to?: LocationDescriptor;
};

function BackButton({ to }: Props) {
	const history = useHistory();

	const handleGoBack = () => {
		if (to) {
			history.replace(to);
		} else {
			history.goBack();
		}
	};

	return <Icon as={UilAngleLeftB} onClick={handleGoBack} cursor="pointer" w={[4, 6]} h={[4, 6]} />;
}

export default BackButton;
