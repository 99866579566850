import memoize from 'fast-memoize';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import { MembershipStatus } from 'shared/constants';

export const DEFAULT_LOCALE = 'en-CA';

export const INVALID_DATE = 'Invalid Date';

export const INVALID_PERCENT = 'Invalid percent';

export const DEFAULT_CURRENCY = 'CAD';

export const formatters = {
	getNumberFormat: memoize((locale = DEFAULT_LOCALE, opts: Intl.NumberFormatOptions) => new Intl.NumberFormat(locale, opts)),
	getDateTimeFormat: memoize(
		(locale = DEFAULT_LOCALE, opts?: Intl.DateTimeFormatOptions) => new Intl.DateTimeFormat(locale, { timeZone: 'UTC', ...opts }),
	),
	getPluralRules: memoize((locale = DEFAULT_LOCALE, opts: Intl.PluralRulesOptions) => new Intl.PluralRules(locale, opts)),
};

export const formatDate = (
	date: string | Date | number,
	{ locales, safe = true, ...options }: Intl.DateTimeFormatOptions & { locales?: string | string[]; safe?: boolean },
) => {
	try {
		if (safe && !date) {
			return INVALID_DATE;
		}
		const value = isString(date) ? new Date(date) : date;
		return formatters.getDateTimeFormat(locales, options).format(value);
	} catch (error) {
		console.error(error);
		return INVALID_DATE;
	}
};

export const formatPercent = (value: number, options?: Intl.NumberFormatOptions) => {
	try {
		return formatters
			.getNumberFormat(DEFAULT_LOCALE, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2, ...options })
			.format(value / 100);
	} catch (error) {
		console.error(error);
		return INVALID_PERCENT;
	}
};

export const formatTimer = (diffSeconds: number) => {
	const diff = typeof diffSeconds === 'number' && diffSeconds >= 0 ? diffSeconds : 0;
	const seconds = diff % 60; // Seconds that cannot be written in minutes
	const secondsInMinutes = (diff - seconds) / 60; // Gives the seconds that COULD be given in minutes
	const minutes = secondsInMinutes % 60; // Minutes that cannot be written in hours
	const hours = (secondsInMinutes - minutes) / 60;
	const formatter = formatters.getNumberFormat(DEFAULT_LOCALE, {
		minimumIntegerDigits: 2,
	});

	return `${formatter.format(hours)}:${formatter.format(minutes)}:${formatter.format(seconds)}`;
};

export const convertTimeToSeconds = (time: string = '') => {
	try {
		const [hours, minutes, seconds] = time.split(':').map(Number);
		const result = hours * 3600 + minutes * 60 + seconds;
		return isNaN(result) ? 0 : result;
	} catch (error) {
		return 0;
	}
};

function isValidNumber(num: number) {
	return !isNaN(num) && isNumber(num);
}

export function roundOffResult(value: number) {
	return Number(value.toFixed(2));
}

export function feetToMeter(feet: number) {
	if (!isValidNumber(feet)) {
		return 0;
	}

	return roundOffResult(feet / 3.2808);
}

export function inchToMeter(inch: number) {
	if (!isValidNumber(inch)) {
		return 0;
	}

	return roundOffResult(inch / 39.37);
}

export function poundsToKilograms(pounds: number) {
	if (!isValidNumber(pounds)) {
		return 0;
	}

	return roundOffResult(pounds / 2.2046);
}

export function kilogramsToPounds(kilograms: number) {
	if (!isValidNumber(kilograms)) {
		return 0;
	}

	return Math.round(kilograms * 2.2046);
}

export function toMembershipStatus(status: MembershipStatus) {
	if (status === MembershipStatus.ACTIVE) {
		return 'MEMBERSHIP';
	} else {
		return 'NO MEMBERSHIP';
	}
}

export function formatNumberToCurrency(num: number) {
	if (!isValidNumber(num)) return 0;
	return formatters
		.getNumberFormat(DEFAULT_LOCALE, {
			style: 'currency',
			currency: DEFAULT_CURRENCY,
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		})
		.format(num);
}
