import React from 'react';
import { Box, Spinner, SpinnerProps, Text } from '@chakra-ui/react';

type Props = SpinnerProps;

export default function PageLoading(props: Props) {
	return (
		<Box w="100%" mt={5} textAlign="center">
			<Spinner size="xl" color="brand.500" emptyColor="gray.200" thickness="4px" speed="0.65s" {...props} />
			<Text>Loading...</Text>
		</Box>
	);
}
