import React from 'react';
import { Tabs } from '@chakra-ui/react';

import TopNavBar from 'routes/components/TopNavBar';
import { routes } from 'routes/constants';

import NewCoachContainer from '../containers/NewCoach.container';

function NewCoachPage() {
	return (
		<Tabs>
			<TopNavBar
				title="Add New Coach"
				goBackTo={`/${routes.coaches}`}
				tabs={[{ label: 'All Coaches', to: `/${routes.coaches}` }]}
			/>
			<NewCoachContainer />
		</Tabs>
	);
}

export default NewCoachPage;
