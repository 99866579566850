const Tabs = {
	defaultProps: {
		colorScheme: 'brand',
	},
	variants: {
		line: {
			tab: {
				borderBottomWidth: 0,
				fontFamily: 'Actay',
				fontWeight: 400,
				_focus: { boxShadow: 'none' },
				_selected: {
					color: '#000',
					fontWeight: 800,
					fontSize: '16px',
				},
			},
			tablist: {
				borderBottom: 'unset',
			},
			tabpanel: {
				py: 0,
			},
		},
	},
};

export default Tabs;
