import React from 'react';
import { FormControlProps, InputProps } from '@chakra-ui/react';
import { ControllerRenderProps } from 'react-hook-form';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import type { AutoCompleteInputProps, AutoCompleteProps } from '@choc-ui/chakra-autocomplete';
import type { FieldError, FieldValues } from 'react-hook-form';

type FieldProps = ControllerRenderProps<FieldValues, any>;

const VALID_AUTOCOMPLETE_PROPS = [
	'closeOnBlur',
	'closeOnSelect',
	'creatable',
	'defaultIsOpen',
	'emphasize',
	'defaultValues',
	'emptyState',
	'filter',
	'focusInputOnSelect',
	'maxSelections',
	'maxSuggestions',
	'multiple',
	'onSelectOption',
	'onOptionFocus',
	'onTagRemoved',
	'rollNavigation',
	'openOnFocus',
	'selectOnFocus',
	'shouldRenderSuggestions',
	'suggestWhenEmpty',
	'listAllValuesOnFocus',
];

export const getFormControlProps = <T extends Partial<FormControlProps>>(
	{ label, isDisabled, isRequired, isInvalid, size, maxW }: T,
	error: FieldError | undefined,
) => ({
	label,
	isDisabled,
	isRequired,
	size,
	maxW,
	isInvalid: !!error || isInvalid,
});

export const getInputProps = <T extends Partial<InputProps>>({ maxW, onBlur, ...rest }: T, field: FieldProps) => ({
	...rest,
	...field,
	onBlur: (ev: React.FocusEvent<HTMLInputElement>) => {
		field.onBlur();
		onBlur?.(ev);
	},
});

export const getAutoCompleteInputProps = <T extends Partial<AutoCompleteInputProps>>(props: T, field: FieldProps) => ({
	...omit(props, VALID_AUTOCOMPLETE_PROPS),
	...field,
});

export const getAutoCompleteProps = <T extends Partial<AutoCompleteProps>>(props: T) => pick(props, VALID_AUTOCOMPLETE_PROPS);
