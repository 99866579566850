import React from 'react';
import { Box, Heading, HStack, Icon, Spacer, Stack, VStack } from '@chakra-ui/react';
import { Control, useFormState } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import UilCheck from '@iconscout/react-unicons/icons/uil-check';

import { routes } from 'routes/constants';
import AlertErrorMessage from 'shared/components/AlertErrorMessage';
import CardFormContainer from 'shared/components/CardFormContainer';
import { FieldInput } from 'shared/components/form';
import OnlyNumbersInput from 'shared/components/OnlyNumbersInput';
import PrimaryButton from 'shared/components/PrimaryButton';
import SecondaryButton from 'shared/components/SecondaryButton';

import { formKeys, forms } from '../constants';

import type { NewCoachFormValues } from '../types';

type Props = {
	control: Control<NewCoachFormValues>;
	errorMessage?: string;
};

function NewCoachComponent({ control, errorMessage }: Props) {
	const { isSubmitting, isSubmitSuccessful, isValid } = useFormState({ control });
	return (
		<CardFormContainer>
			<Heading size="md" mb={12}>
				Add New Coach
			</Heading>
			<VStack align="stretch" spacing={6}>
				<Stack direction={['column', 'row']} spacing={6}>
					<FieldInput
						name={formKeys.newCoach.firstName}
						control={control}
						label={forms.newCoach.labels[formKeys.newCoach.firstName]}
						placeholder={forms.newCoach.placeholders[formKeys.newCoach.firstName]}
						autoComplete="off"
					/>
					<FieldInput
						name={formKeys.newCoach.email}
						control={control}
						label={forms.newCoach.labels[formKeys.newCoach.email]}
						placeholder={forms.newCoach.placeholders[formKeys.newCoach.email]}
						autoComplete="off"
					/>
				</Stack>
				<Stack direction={['column', 'row']} spacing={6}>
					<FieldInput
						name={formKeys.newCoach.lastName}
						control={control}
						label={forms.newCoach.labels[formKeys.newCoach.lastName]}
						placeholder={forms.newCoach.placeholders[formKeys.newCoach.lastName]}
						autoComplete="off"
					/>
					<FieldInput
						as={OnlyNumbersInput}
						name={formKeys.newCoach.phone}
						control={control}
						label={forms.newCoach.labels[formKeys.newCoach.phone]}
						placeholder={forms.newCoach.placeholders[formKeys.newCoach.phone]}
						autoComplete="off"
						maxLength={10}
					/>
				</Stack>
				<Stack direction="row" align="center" spacing={[0, 6]}>
					<Box flex="1">
						{!isSubmitSuccessful ? (
							<SecondaryButton type="submit" mt={8} isLoading={isSubmitting} isDisabled={!isValid} isFullWidth color="brand.500">
								ADD NEW COACH
							</SecondaryButton>
						) : (
							<PrimaryButton
								as={RouterLink}
								mt={8}
								to={`/${routes.coaches}`}
								replace
								rightIcon={<Icon as={UilCheck} />}
								isFullWidth>
								COACH INVITE SENT
							</PrimaryButton>
						)}
					</Box>
				</Stack>
			</VStack>
			{!isSubmitting && errorMessage && (
				<HStack>
					<Spacer flex={[0, 1]} />
					<Box flex="1">
						<AlertErrorMessage>{errorMessage}</AlertErrorMessage>
					</Box>
				</HStack>
			)}
		</CardFormContainer>
	);
}

export default NewCoachComponent;
