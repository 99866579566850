import React from 'react';
import { Redirect } from 'react-router-dom';

import { selectUserToken } from 'features/auth/store/selectors';
import { routes } from 'routes/constants';
import { useAppSelector } from 'shared/store/hooks';

function RootRedirect() {
	const userToken = useAppSelector(selectUserToken);

	return <Redirect to={userToken ? `/${routes.dashboard}` : `/${routes.login}`} />;
}

export default RootRedirect;
