import { set } from 'react-hook-form';
import { createReducer, EntityId, EntityState, nanoid } from '@reduxjs/toolkit';
import { arrayMoveImmutable } from 'array-move';

// import produce from 'immer';
import * as utils from 'shared/utils';

import * as actions from '../actions';
import {
	blockAdapter,
	clientExerciseAdapter,
	// blockAdapter,
	clientWorkoutAdapter,
	dayAdapter,
	// dayAdapter,
	exercisesAdapter,
	// weekAdapter,
	// weeksAdapter,
	// workoutsAdapter,
} from '../adapters';

// import { workoutsSelectors } from '../selectors';
import type {
	Block,
	ClientExercise,
	ClientWorkout,
	Day,
	// Exercise,
	ExerciseState,
	// ExerciseWorkout,
	SectionState,
	Set,
	Week,
	WeekState,
	WorkoutState,
} from 'features/programs/types';

interface State {
	// weeks: EntityState<WeekState>;
	// workouts: EntityState<WorkoutState>;
	exercises: EntityState<ExerciseState>;
	clientWorkout: EntityState<ClientWorkout>;
	//week: EntityState<Week>;
	day: EntityState<Day>;
	block: EntityState<Block>;
	clientExercise: EntityState<ClientExercise>;
	// set: EntityState<Set>;
}

// Function to merge array in sorted order
// function sortedMerge(a, b, res, n, m) {
//  // Sorting a[] and b[]
//  a.sort((a, b) => a.index - b.index);
//  b.sort((a, b) => a.index - b.index);

//  // Merge two sorted arrays into res[]
//  let i = 0,
//      j = 0,
//      k = 0;
//  while (i < n && j < m) {
//      if (a[i].index <= b[j].index) {
//          res[k] = a[i];
//          i += 1;
//          k += 1;
//      } else {
//          res[k] = b[j];
//          j += 1;
//          k += 1;
//      }
//  }

//  // Merging remaining
//  // elements of a[] (if any)
//  while (i < n) {
//      res[k] = a[i];
//      i += 1;
//      k += 1;
//  }

//  // Merging remaining
//  // elements of b[] (if any)
//  while (j < m) {
//      res[k] = b[j];
//      j += 1;
//      k += 1;
//  }
// }

const createWeek = (): WeekState => ({
	id: nanoid(),
	workoutIds: [nanoid()],
	createdAt: new Date().toISOString(),
});

// const createSection = (): SectionState => ({ id: nanoid() });

// const createWorkout = (weekId: EntityId, id?: EntityId): WorkoutState => ({
//  id: id || nanoid(),
//  weekId: weekId,
//  sections: [createSection()],
//  exercises: [],
// });

// const weekSelectors = weeksAdapter.getSelectors();
// const workoutSelectors = workoutsAdapter.getSelectors();
const exerciseSelectors = exercisesAdapter.getSelectors();

const clientWorkoutSelectors = clientWorkoutAdapter.getSelectors();
// const weekSelectors = weekAdapter.getSelectors();
const daySelectors = dayAdapter.getSelectors();
const blockSelectors = blockAdapter.getSelectors();
const clientExerciseSelectors = clientExerciseAdapter.getSelectors();

const createNewSet = (set_index?: number, expected_weight?: number, expected_duration?: string, expected_rep?: number): Set => {
	return {
		id: nanoid(),
		set_index: set_index || 1,
		expected_duration: expected_duration || '0:00:30',
		expected_weight: expected_weight || 0,
		expected_rep: expected_rep || 10,
	};
};

const createNewExercise = (
	blockId: EntityId,
	templateId: string,
	templateType: string,
	exercise_rest?: string,
): ClientExercise => {
	return {
		blockId: blockId,
		id: nanoid(),
		templateId: templateId,
		templateType: templateType,
		set_data: [createNewSet()],
		exercise_rest: exercise_rest || '0:00:15',
	};
};

const createNewBlock = (dayId: EntityId, block_index?: number): Block => ({
	dayId: dayId,
	id: nanoid(),
	block_rest: '0:00:45',
	block_type: 'WARMUP',
	block_name: 'WARMUP',
});

const createNewDay = (weekId: EntityId): Day => {
	return {
		weekId: weekId,
		id: nanoid(),
	};
};

const createNewWeek = (): Week => {
	return {
		id: nanoid(),
	};
};

const createNewWorkout = (week: EntityId): ClientWorkout => {
	return {
		id: nanoid(),
		weekly_data: [week],
	};
};

const createNewWeekAndWorkout = () => {
	const week = createNewWeek();
	const day = createNewDay(week.id);
	const block = createNewBlock(day.id);
	const workout = createNewWorkout(week.id);
	return {
		block,
		day,
		week,
		workout,
	};
};

// const createWeekAndWorkout = () => {
//  const week = createWeek();
//  const workout = createWorkout(week.id, week.workoutIds[0]);
//  return {
//      week,
//      workout,
//  };
// };
// const { week: initialWeek, workout: initialWorkout } = createWeekAndWorkout();

const { block: initialblock, day: initialday, week: initialweek, workout: initialclientWorkout } = createNewWeekAndWorkout();

const initialState: State = {
	// weeks: weeksAdapter.addOne(weeksAdapter.getInitialState(), initialWeek),
	// workouts: workoutsAdapter.addOne(workoutsAdapter.getInitialState(), initialWorkout),
	exercises: exercisesAdapter.getInitialState(),
	clientWorkout: clientWorkoutAdapter.addOne(clientWorkoutAdapter.getInitialState(), initialclientWorkout),
	// week: weekAdapter.addOne(weekAdapter.getInitialState(), initialweek),
	day: dayAdapter.addOne(dayAdapter.getInitialState(), initialday),
	block: blockAdapter.addOne(blockAdapter.getInitialState(), initialblock),
	clientExercise: clientExerciseAdapter.getInitialState(),
};

const programReducer = createReducer(initialState, (builder) => {
	// weeks cases
	builder.addCase(actions.addWeek, (state) => {
		const { week: newWeek, workout: newWorkout } = createNewWeekAndWorkout();
		// weekAdapter.addOne(state.week, newWeek);
		clientWorkoutAdapter.addOne(state.clientWorkout, newWorkout);
	});

	builder.addCase(actions.addWeekEmpty, (state, action) => {
		const { workoutId } = action.payload;
		const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		if (workout) {
			const new_week = createWeek();
			const new_day = createNewDay(new_week.id);
			dayAdapter.addOne(state.day, new_day);
			const new_block = createNewBlock(new_day.id);
			blockAdapter.addOne(state.block, new_block);
			const updatedWorkout = {
				...workout,
				weekly_data: [...workout.weekly_data, new_week.id],
			};
			clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		}
	});

	builder.addCase(actions.duplicateWeek, (state, action) => {
		const { workoutId, weekId } = action.payload;

		const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		const weeks = workout?.weekly_data.filter((wk) => wk == weekId);

		if (workout) {
			weeks?.forEach((week) => {
				const newWeek = createNewWeek();
				const daysofWeek = daySelectors.selectAll(state.day).filter((day) => day.weekId === week);
				const copiedDays = daysofWeek.map((day) => {
					const newDay = {
						...day,
						id: nanoid(),
						weekId: newWeek.id,
					};
					const blocksOfDay = blockSelectors.selectAll(state.block).filter((block) => block.dayId == day.id);
					const copiedBlocks = blocksOfDay.map((block) => {
						const newBlock = {
							...block,
							id: nanoid(),
							dayId: newDay.id,
						};
						const exerciseOfBlocks = clientExerciseSelectors
							.selectAll(state.clientExercise)
							.filter((exercise) => exercise.blockId == block.id);
						const copiedExercise = exerciseOfBlocks.map((exercise) => {
							return {
								...exercise,
								blockId: newBlock.id,
								id: nanoid(),
							};
						});
						clientExerciseAdapter.addMany(state.clientExercise, copiedExercise);
						return newBlock;
					});
					blockAdapter.addMany(state.block, copiedBlocks);
					return newDay;
				});
				dayAdapter.addMany(state.day, copiedDays);
				const updatedWorkout = {
					...workout,
					weekly_data: [...workout.weekly_data, newWeek.id],
				};
				clientWorkoutAdapter.updateOne(state.clientWorkout, { id: workout.id, changes: updatedWorkout });
			});
		}

		// let week_to_copy = workout?.weekly_data?.filter((week) => week.id == weekId);
		// if (workout && week_to_copy && week_to_copy[0]) {
		//  const week = {
		//      ...week_to_copy[0],
		//      daily_data: week_to_copy[0].daily_data.map((day) => {
		//          const day_to_copy = JSON.parse(JSON.stringify(day));
		//          return {
		//              ...day_to_copy,
		//              id: nanoid(),
		//          };
		//      }),
		//      id: nanoid(),
		//      week_index: workout.weekly_data?.length || 0 + 1,
		//  };
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: [...workout.weekly_data, week],
		//  };
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.setWorkout, (state, action) => {
		const { workoutData } = action.payload;
		const weekIds = workoutData.weekly_data.map((week) => {
			const newWeek = createNewWeek();
			const day = week.daily_data.map((day) => {
				const newDay = {
					// ...day,
					id: nanoid(),
					weekId: newWeek.id,
					day_start: day.day_start,
					day_end: day.day_end,
				};
				const block = day.block_data.map((block) => {
					const newBlock = {
						// ...block,
						id: nanoid(),
						dayId: newDay.id,
						block_name: block.block_name,
						block_type: block.block_type,
						block_rest: utils.formatTimer(block.block_rest ?? 0).substring(1),
						block_start: block.block_start,
						block_end: block.block_end,
					};

					const exercises = block.exercise.map((exercise) => {
						const { exercise_equipment, ...rest } = exercise;
						return {
							// ...rest,
							id: nanoid(),
							blockId: newBlock.id,
							templateType: exercise.templateType,
							templateId: exercise.templateId,
							exercise_rest: utils.formatTimer(exercise.exercise_rest ?? 0).substring(1),
							set_data: exercise.set_data.map((set) => {
								return {
									set_index: set.set_index,
									expected_duration: utils.formatTimer(set.expected_duration ?? 0).substring(1),
									expected_weight: set.expected_weight ?? 0,
									expected_rep: set.expected_rep,
									id: nanoid(),
									exercise_start: set.exercise_start,
									exercise_end: set.exercise_end,
								};
							}),
						};
					});
					clientExerciseAdapter.addMany(state.clientExercise, exercises);
					return newBlock;
				});
				blockAdapter.addMany(state.block, block);
				return newDay;
			});
			dayAdapter.addMany(state.day, day);
			return newWeek.id;
		});
		const workouts = clientWorkoutSelectors.selectAll(state.clientWorkout);
		if (workouts && workouts[0]) {
			const updateWorkout = {
				id: nanoid(),
				serviceId: workoutData.serviceId,
				transactionId: workoutData.transactionId,
				title: workoutData.title,
				weekly_data: [...weekIds],
				currentDay: workoutData.currentDay,
				currentWeek: workoutData.currentWeek,
			};
			clientWorkoutAdapter.setAll(state.clientWorkout, [updateWorkout]);
		}
		//  const updatedWorkout = {
		//      id: nanoid(),
		//      ...(workoutData.title ? { title: workoutData.title } : {}),
		//      weekly_data: workoutData.weekly_data.map((week) => {
		//          return {
		//              id: nanoid(),
		//              week_index: week?.week ?? week?.week_index,
		//              daily_data: week.daily_data.map((dayData) => {
		//                  return {
		//                      day_index: dayData.day_index,
		//                      id: nanoid(),
		//                      block_data: dayData.block_data.map((block) => {
		//                          return {
		//                              block_type: block.block_type,
		//                              block_index: block.block_index,
		//                              block_name: block.block_name,
		//                              block_rest: utils.formatTimer(block.block_rest ?? 0).substring(1),
		//                              id: nanoid(),
		//                              exercise: block.exercise.map((exercise) => {
		//                                  return {
		//                                      exercise_index: exercise.exercise_index,
		//                                      templateId: exercise.templateId,
		//                                      templateType: exercise.templateType,
		//                                      exercise_rest: utils.formatTimer(exercise.exercise_rest ?? 0).substring(1),
		//                                      id: nanoid(),
		//                                      set_data: exercise.set_data.map((set) => {
		//                                          return {
		//                                              set_index: set.set_index,
		//                                              expected_duration: utils.formatTimer(set.expected_duration ?? 0).substring(1),
		//                                              expected_weight: set.expected_weight ?? 0,
		//                                              expected_rep: set.expected_rep,
		//                                              id: nanoid(),
		//                                          };
		//                                      }),
		//                                  };
		//                              }),
		//                          };
		//                      }),
		//                  };
		//              }),
		//          };
		//      }),
		//  };
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.duplicateDay, (state, action) => {
		const { dayId } = action.payload;
		const day = daySelectors.selectById(state.day, dayId);
		if (day) {
			const newDay = createNewDay(day.weekId);
			const blocksOfDay = blockSelectors.selectAll(state.block).filter((block) => block.dayId == day.id);
			const copiedBlocks = blocksOfDay.map((block) => {
				const newBlock = {
					...block,
					id: nanoid(),
					dayId: newDay.id,
				};
				const exerciseOfBlocks = clientExerciseSelectors
					.selectAll(state.clientExercise)
					.filter((exercise) => exercise.blockId == block.id);
				const copiedExercise = exerciseOfBlocks.map((exercise) => {
					return {
						...exercise,
						blockId: newBlock.id,
						id: nanoid(),
					};
				});
				clientExerciseAdapter.addMany(state.clientExercise, copiedExercise);
				return newBlock;
			});
			blockAdapter.addMany(state.block, copiedBlocks);
			dayAdapter.addOne(state.day, newDay);
			// let day_to_copy: Day[];
			// const week = workout.weekly_data?.filter((week) => week.id == weekId);
			// if (week && week[0]) {
			//  day_to_copy = week[0].daily_data.filter((day) => day.id == dayId);
			//  if (day_to_copy && day_to_copy[0]) {
			//      const day = JSON.parse(JSON.stringify(day_to_copy[0]));
			//      day.id = nanoid();
			//      day.day_index = week[0].daily_data.length + 1;
			//      // console.log(day);
			//      const updatedWorkout = {
			//          ...workout,
			//          weekly_data: workout.weekly_data?.map((week) => {
			//              if (week.id == weekId) {
			//                  return {
			//                      ...week,
			//                      daily_data: [...week.daily_data, day],
			//                  };
			//              }
			//              return week;
			//          }),
			//      };
			//      clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
			//  }
			// }
		}
	});

	builder.addCase(actions.removeWeek, (state, action) => {
		const { workoutId, weekId } = action.payload;
		const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);

		if (workout) {
			const updatedWorkout = {
				...workout,
				weekly_data: workout.weekly_data.filter((week) => week != weekId),
			};
			const days = daySelectors.selectAll(state.day);
			days
				.filter((day) => day.weekId === weekId)
				.forEach((day) => {
					const block = blockSelectors.selectAll(state.block);
					block
						.filter((block) => block.dayId === day.id)
						.forEach((block) => {
							const exercise = clientExerciseSelectors.selectAll(state.clientExercise);
							exercise
								.filter((exercise) => exercise.blockId === block.id)
								.forEach((exercise) => {
									clientExerciseAdapter.removeOne(state.clientExercise, exercise.id);
								});
							blockAdapter.removeOne(state.block, block.id);
						});
					dayAdapter.removeOne(state.day, day.id);
				});

			// const blocks=blockSelectors.selectAll(state.block);
			// const blocktoDelete=blocks.filter(block=>block.)
			// const updatedWorkout = {
			//  ...workout,
			//  weekly_data: workout.weekly_data
			//      .filter((week) => week.id != weekId)
			//      .map((week, index) => {
			//          return {
			//              ...week,
			//              week_index: index + 1,
			//          };
			//      }),
			// };

			// Update the state using setAll
			clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		}
	});

	// clear or reset new program state
	builder.addCase(actions.resetNewProgram, (state) => {
		// console.log('reset fired->', state.clientWorkout);
		// const { block: newBlock, day: newDay, workout: newWorkout } = createNewWeekAndWorkout();
		// if (state.clientWorkout) {
		// 	console.log('reset clientWorkout->', state.clientWorkout);
		// 	state.clientWorkout = clientWorkoutAdapter.getInitialState();
		// 	state.block = blockAdapter.getInitialState();
		// 	state.day = dayAdapter.getInitialState();
		// 	state.clientExercise = clientExerciseAdapter.getInitialState();
		// }
		// console.log('reset clientWorkout not->', state.clientWorkout);
		// // weekAdapter.setAll(state.week, [newWeek]);
		// clientWorkoutAdapter.setAll(state.clientWorkout, [newWorkout]);
		// blockAdapter.setAll(state.block, [newBlock]);
		// dayAdapter.setAll(state.day, [newDay]);
		// return initialState;
		return {
			exercises: state.exercises,
			clientWorkout: initialState.clientWorkout,
			day: initialState.day,
			block: initialState.block,
			clientExercise: initialState.clientExercise,
		};
	});

	// clear or reset program info state
	// builder.addCase(actions.resetProgramInfo, (state) => {
	//  // weeksAdapter.setAll(state.weeks, []);
	//  // workoutsAdapter.setAll(state.workouts, []);
	//  clientWorkoutAdapter.setAll(state.clientWorkout, []);
	// });

	// exercises cases
	builder.addCase(actions.setAllExercises, (state, action) => {
		exercisesAdapter.setAll(state.exercises, action.payload);
	});

	builder.addCase(actions.removeAllExercises, (state) => {
		exercisesAdapter.removeAll(state.exercises);
	});

	// builder.addCase(actions.addWorkoutExercise, (state, action) => {
	//  const { templateId, workoutId, sectionId } = action.payload;
	//  const exercise = exerciseSelectors.selectById(state.exercises, templateId);
	//  const workout = workoutSelectors.selectById(state.workouts, workoutId);

	//  if (workout && exercise && sectionId) {
	//      workoutsAdapter.updateOne(state.workouts, {
	//          id: workoutId,
	//          changes: {
	//              exercises: [...workout.exercises, { templateId, sectionId }],
	//          },
	//      });
	//  }
	// });

	// builder.addCase(actions.removeWorkoutExercise, (state, action) => {
	//  const { workoutId, sectionId, exerciseIndexBySection, exercisesCurrentWorkout } = action.payload;
	//  const workout = workoutSelectors.selectById(state.workouts, workoutId);
	//  if (workout) {
	//      const exercisesCurrentSection = exercisesCurrentWorkout?.filter((exercise) => exercise.sectionId === sectionId);
	//      const newExercisesCurrentSection = exercisesCurrentSection?.filter((_, index) => index !== exerciseIndexBySection);

	//      const exercisesOtherSections = workout.exercises.filter((exercise) => exercise.sectionId !== sectionId);
	//      const exercisesOtherSectionsform = exercisesCurrentWorkout?.filter((exercise) => exercise.sectionId !== sectionId);
	//      const exercises = exercisesOtherSections.map((item, index) => ({ ...item, ...exercisesOtherSectionsform[index] }));

	//      const ans = [...newExercisesCurrentSection, ...exercises];

	//      // @ts-ignore awain
	//      ans.sort((a, b) => a.index - b.index);

	//      workoutsAdapter.updateOne(state.workouts, {
	//          id: workoutId,
	//          changes: {
	//              exercises: ans,
	//          },
	//      });
	//  }
	// });

	// builder.addCase(actions.reorderWorkoutExercises, (state, action) => {
	//  const { workoutId, oldIndex, sectionId, newIndex, exercisesCurrentWorkout } = action.payload;
	//  const workout = workoutSelectors.selectById(state.workouts, workoutId);
	//  if (workout) {
	//      const exercisesFromForms = exercisesCurrentWorkout.filter((exercise) => exercise.sectionId === sectionId);
	//      const exercisesFromState = workout.exercises.filter((exercise) => exercise.sectionId === sectionId);
	//      const exercises = exercisesFromState.map((item, index) => ({ ...item, ...exercisesFromForms[index] }));
	//      const newExercisesCurrentSection = arrayMoveImmutable(exercises, oldIndex, newIndex);

	//      const exercisesOtherSec = workout.exercises.filter((exercise) => exercise.sectionId !== sectionId);
	//      const exercisesOtherSectionscomplete = exercisesCurrentWorkout.filter((exercise) => exercise.sectionId !== sectionId);

	//      const exercisesOtherSections = exercisesOtherSec.map((item, index) => ({
	//          ...item,
	//          ...exercisesOtherSectionscomplete[index],
	//      }));

	//      let n = newExercisesCurrentSection.length;
	//      let m = exercisesOtherSections.length;

	//      // Final merge list
	//      let res = new Array(n + m);

	//      sortedMerge(newExercisesCurrentSection, exercisesOtherSections, res, n, m);

	//      workoutsAdapter.updateOne(state.workouts, {
	//          id: workoutId,
	//          changes: {
	//              exercises: res,
	//          },
	//      });
	//  }
	// });

	builder.addCase(actions.addRemoveExerciseSet, (state, actions) => {
		const { exerciseId, totalSets } = actions.payload;
		const exercise = clientExerciseSelectors.selectById(state.clientExercise, exerciseId);
		if (exercise) {
			let updatedExercise;

			if (totalSets > exercise.set_data.length) {
				updatedExercise = {
					...exercise,
					set_data: [
						...exercise.set_data,
						...Array.from({ length: totalSets - exercise.set_data.length }, (_, index) =>
							createNewSet(exercise.set_data.length + index + 1),
						),
					],
				};
			} else {
				updatedExercise = {
					...exercise,
					set_data: exercise.set_data.slice(0, totalSets),
				};
			}
			clientExerciseAdapter.updateOne(state.clientExercise, { id: exercise.id, changes: updatedExercise });
		}
	});

	builder.addCase(actions.addDay, (state, action) => {
		const { weekId } = action.payload;
		const new_day = createNewDay(weekId);
		dayAdapter.addOne(state.day, new_day);
		const new_block = createNewBlock(new_day.id);
		blockAdapter.addOne(state.block, new_block);

		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		//find the week from workout
		// if (workout) {
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: workout.weekly_data?.map((week) => {
		//          if (week.id == weekId) {
		//              return {
		//                  ...week,
		//                  daily_data: [...week.daily_data, createNewDay(createNewBlock(1), week.daily_data.length + 1)],
		//              };
		//          }
		//          return week;
		//      }),
		//  };
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.addBlock, (state, action) => {
		const { dayId } = action.payload;

		const newBlock = createNewBlock(dayId);
		blockAdapter.addOne(state.block, newBlock);

		// Use the selectById selector to get the workout by its id
		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		// if (workout) {
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: workout.weekly_data?.map((week) => {
		//          if (week.id === weekId) {
		//              return {
		//                  ...week,
		//                  daily_data: week.daily_data.map((day) => {
		//                      if (day.id === dayId) {
		//                          return {
		//                              ...day,
		//                              block_data: [...day.block_data, createNewBlock(day.block_data.length + 1)],
		//                          };
		//                      }
		//                      return day;
		//                  }),
		//              };
		//          }
		//          return week;
		//      }),
		//  };

		//  // Use upsertOne to update the state
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.updateBlockType, (state, action) => {
		const { blockId, blockType } = action.payload;

		const block = blockSelectors.selectById(state.block, blockId);
		if (block) {
			const updatedBlock = {
				...block,
				block_type: blockType,
			};
			blockAdapter.updateOne(state.block, { id: block.id, changes: updatedBlock });
		}

		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		// if (workout) {
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: workout.weekly_data?.map((week) => {
		//          if (week.id === weekId) {
		//              return {
		//                  ...week,
		//                  daily_data: week.daily_data.map((day) => {
		//                      if (day.id === dayId) {
		//                          return {
		//                              ...day,
		//                              block_data: day.block_data.map((block) => {
		//                                  if (block.id === blockId) {
		//                                      return {
		//                                          ...block,
		//                                          block_type: blockType,
		//                                      };
		//                                  }
		//                                  return block;
		//                              }),
		//                          };
		//                      }
		//                      return day;
		//                  }),
		//              };
		//          }
		//          return week;
		//      }),
		//  };
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.updateBlockRest, (state, action) => {
		const { blockId, rest } = action.payload;

		const block = blockSelectors.selectById(state.block, blockId);

		if (block) {
			const updatedBlock = {
				...block,
				block_rest: rest,
			};
			blockAdapter.updateOne(state.block, { id: block.id, changes: updatedBlock });
		}

		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		// if (workout) {
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: workout.weekly_data?.map((week) => {
		//          if (week.id === weekId) {
		//              return {
		//                  ...week,
		//                  daily_data: week.daily_data.map((day) => {
		//                      if (day.id === dayId) {
		//                          return {
		//                              ...day,
		//                              block_data: day.block_data.map((block) => {
		//                                  if (block.id === blockId) {
		//                                      return {
		//                                          ...block,
		//                                          block_rest: rest,
		//                                      };
		//                                  }
		//                                  return block;
		//                              }),
		//                          };
		//                      }
		//                      return day;
		//                  }),
		//              };
		//          }
		//          return week;
		//      }),
		//  };
		//  // console.log('updatedwo-> ', updatedWorkout);
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.updateExerciseRest, (state, action) => {
		const { exerciseId, rest } = action.payload;
		const exercise = clientExerciseSelectors.selectById(state.clientExercise, exerciseId);
		if (exercise) {
			const updatedExercise = { ...exercise, exercise_rest: rest };
			clientExerciseAdapter.updateOne(state.clientExercise, { id: exercise.id, changes: updatedExercise });
		}
	});

	builder.addCase(actions.updateSetData, (state, action) => {
		const { exerciseId, setId, setData } = action.payload;
		const exercise = clientExerciseSelectors.selectById(state.clientExercise, exerciseId);
		if (exercise) {
			const updatedExercise = {
				...exercise,
				set_data: exercise.set_data.map((set) => {
					if (set.id == setId) {
						return {
							...set,
							...setData,
						};
					}
					return set;
				}),
			};
			clientExerciseAdapter.updateOne(state.clientExercise, { id: exercise.id, changes: updatedExercise });
		}
		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		// if (workout) {
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: workout.weekly_data?.map((week) => {
		//          if (week.id == weekId) {
		//              return {
		//                  ...week,
		//                  daily_data: week.daily_data.map((day) => {
		//                      if (day.id == dayId) {
		//                          return {
		//                              ...day,
		//                              block_data: day.block_data.map((block) => {
		//                                  if (block.id == blockId) {
		//                                      return {
		//                                          ...block,
		//                                          exercise: block.exercise?.map((exercise) => {
		//                                              if (exercise.id == exerciseId) {
		//                                                  return {
		//                                                      ...exercise,
		//                                                      set_data: exercise.set_data?.map((set) => {
		//                                                          if (set.id == setId) {
		//                                                              return {
		//                                                                  ...set,
		//                                                                  ...setData,
		//                                                              };
		//                                                          }
		//                                                          return set;
		//                                                      }),
		//                                                  };
		//                                              }
		//                                              return exercise;
		//                                          }),
		//                                      };
		//                                  }
		//                                  return block;
		//                              }),
		//                          };
		//                      }
		//                      return day;
		//                  }),
		//              };
		//          }
		//          return week;
		//      }),
		//  };
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.addNewExercise, (state, action) => {
		const { blockId, clientExercise } = action.payload;

		const exercise = createNewExercise(
			blockId,
			clientExercise.templateId,
			clientExercise.templateType,
			// clientExercise.exercise_rest,
		);

		clientExerciseAdapter.addOne(state.clientExercise, exercise);

		// // Retrieve the current workout from the state
		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);

		// // Ensure the workout is found
		// if (workout) {
		//  // Find the target week, day, and block in the workout
		//  const targetWeekIndex = workout.weekly_data.findIndex((week) => week.id === weekId);

		//  if (targetWeekIndex !== -1) {
		//      const targetDayIndex = workout.weekly_data[targetWeekIndex].daily_data.findIndex((day) => day.id === dayId);

		//      if (targetDayIndex !== -1) {
		//          const targetBlockIndex = workout.weekly_data[targetWeekIndex].daily_data[targetDayIndex].block_data.findIndex(
		//              (block) => block.id === blockId,
		//          );

		//          if (targetBlockIndex !== -1) {
		//              // Create a new exercise object
		//              const exercise = createNewExercise(
		//                  clientExercise.templateId,
		//                  clientExercise.templateType,
		//                  (workout.weekly_data[targetWeekIndex].daily_data[targetDayIndex].block_data[targetBlockIndex].exercise?.length ||
		//                      0) + 1,
		//              );

		//              // Create a new block object with the updated exercise array
		//              const updatedBlock = {
		//                  ...workout.weekly_data[targetWeekIndex].daily_data[targetDayIndex].block_data[targetBlockIndex],
		//                  exercise: [
		//                      ...(workout.weekly_data[targetWeekIndex].daily_data[targetDayIndex].block_data[targetBlockIndex].exercise || []),
		//                      exercise,
		//                  ],
		//              };

		//              // Create a new day object with the updated block
		//              const updatedDay = {
		//                  ...workout.weekly_data[targetWeekIndex].daily_data[targetDayIndex],
		//                  block_data: [
		//                      ...workout.weekly_data[targetWeekIndex].daily_data[targetDayIndex].block_data.slice(0, targetBlockIndex),
		//                      updatedBlock,
		//                      ...workout.weekly_data[targetWeekIndex].daily_data[targetDayIndex].block_data.slice(targetBlockIndex + 1),
		//                  ],
		//              };

		//              // Create a new week object with the updated day
		//              const updatedWeek = {
		//                  ...workout.weekly_data[targetWeekIndex],
		//                  daily_data: [
		//                      ...workout.weekly_data[targetWeekIndex].daily_data.slice(0, targetDayIndex),
		//                      updatedDay,
		//                      ...workout.weekly_data[targetWeekIndex].daily_data.slice(targetDayIndex + 1),
		//                  ],
		//              };

		//              // Create a new workout object with the updated week
		//              const updatedWorkout = {
		//                  ...workout,
		//                  weekly_data: [
		//                      ...workout.weekly_data.slice(0, targetWeekIndex),
		//                      updatedWeek,
		//                      ...workout.weekly_data.slice(targetWeekIndex + 1),
		//                  ],
		//              };

		//              // Update the workout in the state using clientWorkoutAdapter
		//              clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		//          }
		//      }
		//  }
		// }
	});

	builder.addCase(actions.removeExerciseWorkout, (state, action) => {
		const { exerciseId } = action.payload;

		clientExerciseAdapter.removeOne(state.clientExercise, exerciseId);
		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);

		// if (workout) {
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: (workout.weekly_data || []).map((week) => {
		//          if (week.id === weekId) {
		//              return {
		//                  ...week,
		//                  daily_data: (week.daily_data || []).map((day) => {
		//                      if (day.id === dayId) {
		//                          return {
		//                              ...day,
		//                              block_data: (day.block_data || []).map((block) => {
		//                                  if (block.id === blockId) {
		//                                      return {
		//                                          ...block,
		//                                          exercise: (block.exercise || []).filter((exercise) => exercise.id !== exerciseId),
		//                                      };
		//                                  }
		//                                  return block;
		//                              }),
		//                          };
		//                      }
		//                      return day;
		//                  }),
		//              };
		//          }
		//          return week;
		//      }),
		//  };
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.removeDay, (state, action) => {
		const { dayId } = action.payload;

		const day = daySelectors.selectById(state.day, dayId);
		if (day) {
			const block = blockSelectors.selectAll(state.block);
			block
				.filter((block) => block.dayId === day.id)
				.forEach((block) => {
					const exercise = clientExerciseSelectors.selectAll(state.clientExercise);
					exercise
						.filter((exercise) => exercise.blockId === block.id)
						.forEach((exercise) => {
							clientExerciseAdapter.removeOne(state.clientExercise, exercise.id);
						});
					blockAdapter.removeOne(state.block, block.id);
				});
			dayAdapter.removeOne(state.day, day.id);
		}

		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);

		// if (workout) {
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: (workout.weekly_data || []).map((week) => {
		//          if (week.id === weekId) {
		//              return {
		//                  ...week,
		//                  daily_data: (week.daily_data || [])
		//                      .filter((day) => day.id !== dayId)
		//                      .map((day, index) => {
		//                          // Update day_index for elements after the removed day
		//                          return {
		//                              ...day,
		//                              day_index: index + 1,
		//                          };
		//                      }),
		//              };
		//          }
		//          return week;
		//      }),
		//  };

		//  // Update the state using setAll
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.removeBlock, (state, action) => {
		const { blockId } = action.payload;

		const block = blockSelectors.selectById(state.block, blockId);
		if (block) {
			const exercises = clientExerciseSelectors.selectAll(state.clientExercise);
			const deletedExercise = exercises.filter((exercise) => exercise.blockId === block.id).map((exercise) => exercise.id);
			clientExerciseAdapter.removeMany(state.clientExercise, deletedExercise);
		}
		blockAdapter.removeOne(state.block, blockId);

		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);

		// if (workout) {
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: (workout.weekly_data || []).map((week) => {
		//          if (week.id === weekId) {
		//              return {
		//                  ...week,
		//                  daily_data: (week.daily_data || []).map((day) => {
		//                      if (day.id === dayId) {
		//                          const updatedBlockData = (day.block_data || [])
		//                              .filter((block) => {
		//                                  return block.id !== blockId;
		//                              })
		//                              .map((block, index) => {
		//                                  // Update block_index for elements after the removed block
		//                                  if (block.block_index > index) {
		//                                      return {
		//                                          ...block,
		//                                          block_index: block.block_index - 1,
		//                                      };
		//                                  }
		//                                  return block;
		//                              });

		//                          return {
		//                              ...day,
		//                              block_data: updatedBlockData,
		//                          };
		//                      }
		//                      return day;
		//                  }),
		//              };
		//          }
		//          return week;
		//      }),
		//  };

		//  // Update the state using updateOne
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	// // sections cases
	// builder.addCase(actions.addWorkoutSection, (state, action) => {
	//  const { workoutId } = action.payload;
	//  const workout = workoutSelectors.selectById(state.workouts, workoutId);
	//  if (workout) {
	//      const sections = workout.sections.concat(createSection());
	//      workoutsAdapter.updateOne(state.workouts, {
	//          id: workoutId,
	//          changes: {
	//              sections,
	//          },
	//      });
	//  }
	// });

	// builder.addCase(actions.updateSection, (state, action) => {
	//  const { workoutId, sectionId, groupType } = action.payload;
	//  const workout = workoutSelectors.selectById(state.workouts, workoutId);
	//  if (workout) {
	//      workoutsAdapter.updateOne(state.workouts, {
	//          id: workoutId,
	//          changes: {
	//              sections: workout.sections.map((section) => (section.id === sectionId ? { ...section, groupType } : section)),
	//          },
	//      });
	//  }
	// });

	builder.addCase(actions.updateBlockName, (state, action) => {
		const { blockId, blockName } = action.payload;
		const block = blockSelectors.selectById(state.block, blockId);
		if (block) {
			const updated_block = {
				...block,
				block_name: blockName,
			};
			blockAdapter.updateOne(state.block, { id: block.id, changes: updated_block });
		}
		// const { workoutId, weekId, dayId, blockId, blockName } = action.payload;
		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		// if (workout) {
		//  const updatedWorkout = {
		//      ...workout,
		//      weekly_data: workout.weekly_data.map((week) => {
		//          if (week.id === weekId) {
		//              return {
		//                  ...week,
		//                  daily_data: week.daily_data.map((day) => {
		//                      if (day.id === dayId) {
		//                          return {
		//                              ...day,
		//                              block_data: day.block_data.map((block) => {
		//                                  if (block.id === blockId) {
		//                                      return {
		//                                          ...block,
		//                                          block_name: blockName,
		//                                      };
		//                                  }
		//                                  return block;
		//                              }),
		//                          };
		//                      }
		//                      return day;
		//                  }),
		//              };
		//          }
		//          return week;
		//      }),
		//  };
		//  clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	// builder.addCase(actions.removeWorkoutSection, (state, action) => {
	//  const { workoutId, sectionId, exercisesfromForm } = action.payload;
	//  const workout = workoutSelectors.selectById(state.workouts, workoutId);

	//  const exercisesfromFormNew = exercisesfromForm?.filter((exercise: any) => exercise.sectionId !== sectionId);
	//  if (workout) {
	//      const ex = workout.exercises.filter((exercise) => exercise.sectionId !== sectionId);
	//      const exercises = ex.map((item, index) => ({ ...item, ...exercisesfromFormNew[index] }));
	//      for (var i = 0; i < exercises.length; i++) {
	//          exercises[i].index = i;
	//      }
	//      workoutsAdapter.updateOne(state.workouts, {
	//          id: workoutId,
	//          changes: {
	//              sections: workout.sections.filter((section) => section.id !== sectionId),
	//              exercises: exercises,
	//          },
	//      });
	//  }
	// });

	// builder.addCase(actions.reorderWorkoutSections, (state, action) => {
	//  const { workoutId, oldIndex, newIndex } = action.payload;
	//  const workout = workoutSelectors.selectById(state.workouts, workoutId);
	//  if (workout) {
	//      const sectionsCopy = [...workout.sections];
	//      const sections = arrayMoveImmutable(sectionsCopy, oldIndex, newIndex);
	//      workoutsAdapter.updateOne(state.workouts, {
	//          id: workoutId,
	//          changes: {
	//              sections: sections,
	//          },
	//      });
	//  }
	// });

	// workouts cases

	// builder.addCase(actions.addWorkout, (state, action) => {
	//  const { weekId } = action.payload;
	//  const week = weekSelectors.selectById(state.weeks, weekId);
	//  if (week) {
	//      const newWorkout = createWorkout(week.id);
	//      workoutsAdapter.addOne(state.workouts, newWorkout);
	//      weeksAdapter.updateOne(state.weeks, {
	//          id: weekId,
	//          changes: {
	//              workoutIds: week.workoutIds.concat(newWorkout.id),
	//          },
	//      });
	//  }
	// });

	builder.addCase(actions.reorderExercises, (state, actions) => {
		const { blockId, oldIndex, newIndex } = actions.payload;
		const exercises = clientExerciseSelectors.selectAll(state.clientExercise);
		if (exercises) {
			const { matching, notMatching } = exercises.reduce(
				(acc, exercise) => {
					if (exercise.blockId === blockId) {
						acc.matching.push(exercise);
					} else {
						acc.notMatching.push(exercise);
					}
					return acc;
				},
				{ matching: [], notMatching: [] },
			);

			const updatedOrder = arrayMoveImmutable(matching, oldIndex, newIndex);
			const finalExercises = [...notMatching, ...updatedOrder];
			clientExerciseAdapter.setAll(state.clientExercise, finalExercises);
		}

		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		// if (workout) {
		// 	const updatedWorkout = {
		// 		...workout,
		// 		weekly_data: workout.weekly_data.map((week) => {
		// 			if (week.id == weekId) {
		// 				return {
		// 					...week,
		// 					daily_data: week.daily_data.map((day) => {
		// 						if (day.id == dayId) {
		// 							return {
		// 								...day,
		// 								block_data: day.block_data.map((block) => {
		// 									if (block.id == blockId) {
		// 										const exerciseCopy = [...block.exercise];
		// 										const updated_index_exercises = arrayMoveImmutable(exerciseCopy, oldIndex, newIndex).map(
		// 											(exercise, index) => {
		// 												return {
		// 													...exercise,
		// 													exercise_index: index + 1,
		// 												};
		// 											},
		// 										);

		// 										return {
		// 											...block,
		// 											exercise: [...updated_index_exercises],
		// 										};
		// 									}
		// 									return block;
		// 								}),
		// 							};
		// 						}
		// 						return day;
		// 					}),
		// 				};
		// 			}
		// 			return week;
		// 		}),
		// 	};
		// 	clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	builder.addCase(actions.reorderBlocks, (state, actions) => {
		const { dayId, oldIndex, newIndex } = actions.payload;
		const blocks = blockSelectors.selectAll(state.block);
		if (blocks) {
			const { matching, notMatching } = blocks.reduce(
				(acc, block) => {
					if (block.dayId === dayId) {
						acc.matching.push(block);
					} else {
						acc.notMatching.push(block);
					}
					return acc;
				},
				{ matching: [], notMatching: [] },
			);
			const updatedOrder = arrayMoveImmutable(matching, oldIndex, newIndex);
			const finalBlocks = [...notMatching, ...updatedOrder];
			blockAdapter.setAll(state.block, finalBlocks);
		}
		// const workout = clientWorkoutSelectors.selectById(state.clientWorkout, workoutId);
		// if (workout) {
		// 	const updatedWorkout = {
		// 		...workout,
		// 		weekly_data: workout.weekly_data.map((week) => {
		// 			if (week.id == weekId) {
		// 				return {
		// 					...week,
		// 					daily_data: week.daily_data.map((day) => {
		// 						if (day.id == dayId) {
		// 							const blockCopy = [...day.block_data];
		// 							const updated_index_blocks = arrayMoveImmutable(blockCopy, oldIndex, newIndex).map((block, index) => {
		// 								return {
		// 									...block,
		// 									block_index: index + 1,
		// 								};
		// 							});

		// 							return {
		// 								...day,
		// 								block_data: [...updated_index_blocks],
		// 							};
		// 						}
		// 						return day;
		// 					}),
		// 				};
		// 			}
		// 			return week;
		// 		}),
		// 	};
		// 	clientWorkoutAdapter.setAll(state.clientWorkout, [updatedWorkout]);
		// }
	});

	// builder.addCase(actions.addWorkoutWithInfo, (state, action) => {
	//  const workout = action.payload;
	//  const weeks = weekSelectors.selectAll(state.weeks);
	//  const week = weeks[workout.week - 1];

	//  if (week) {
	//      let numSections = 0;
	//      const sections: SectionState[] = [];
	//      const groupLetterMap = {} as Record<string, string>;
	//      const exercisesInfo = exerciseSelectors.selectAll(state.exercises);

	//      // eslint-disable-next-line array-callback-return
	//      workout.exercises.map((exercise) => {
	//          const match = Object.values(groupLetterMap).find((groupLetter) => groupLetter === exercise.groupLetter);
	//          const section = { id: nanoid(), groupType: exercise.groupType, block_name: exercise.block_name } as SectionState;

	//          if (!match) {
	//              // groupLetterMap[section.id] = String.fromCharCode(65 + numSections++);
	//              groupLetterMap[section.id] = exercise.groupLetter;
	//              sections.push(section);
	//          }
	//      });
	//      sections.sort((a, b) => {
	//          let fa = groupLetterMap[a.id].toLowerCase(),
	//              fb = groupLetterMap[b.id].toLowerCase();

	//          if (fa < fb) {
	//              return -1;
	//          }
	//          if (fa > fb) {
	//              return 1;
	//          }
	//          return 0;
	//      });

	//      const exercises = workout.exercises.map((exercise) => {
	//          const exerciseTemplate = exercisesInfo.find((exerciseDetail) => exerciseDetail.thumbnailUrl === exercise.thumbnailUrl);
	//          const section = sections.find((section) => groupLetterMap[section.id] === exercise.groupLetter);

	//          return {
	//              templateId: exerciseTemplate?.templateId,
	//              sectionId: section?.id,
	//              sets: exercise.sets,
	//              reps: exercise.reps,
	//              weight: utils.kilogramsToPounds(exercise.weight ?? 0),
	//              duration: utils.formatTimer(exercise.duration ?? 0).substring(1),
	//              rest: utils.formatTimer(exercise.rest ?? 0).substring(1),
	//              thumbnailUrl: exerciseTemplate?.thumbnailUrl,
	//              templateType: exerciseTemplate?.templateType,
	//              groupLetter: exercise.groupLetter,
	//              groupType: exercise.groupType,
	//              block_name: exercise.block_name,
	//          } as unknown as ExerciseWorkout;
	//      });

	//      const newWorkout = {
	//          id: nanoid(),
	//          weekId: week.id,
	//          sections,
	//          exercises,
	//      };

	//      workoutsAdapter.addOne(state.workouts, newWorkout);
	//      weeksAdapter.updateOne(state.weeks, {
	//          id: week.id,
	//          changes: {
	//              workoutIds: week.workoutIds.concat(newWorkout.id) ?? [createWorkout(week.id)],
	//          },
	//      });
	//  }
	// });

	// builder.addCase(actions.removeWorkout, (state, action) => {
	//  const { weekId, workoutId } = action.payload;
	//  const week = weekSelectors.selectById(state.weeks, weekId);
	//  const workout = workoutSelectors.selectById(state.workouts, workoutId);
	//  if (week && workout) {
	//      workoutsAdapter.removeOne(state.workouts, workout.id);
	//      weeksAdapter.updateOne(state.weeks, {
	//          id: weekId,
	//          changes: {
	//              workoutIds: week.workoutIds.filter((itemWorkout) => itemWorkout !== workoutId),
	//          },
	//      });
	//  }
	// });

	// builder.addCase(actions.reorderWorkouts, (state, action) => {
	//  const { oldIndex, newIndex } = action.payload;
	//  const workouts = workoutSelectors.selectAll(state.workouts);
	//  if (workouts) {
	//      const workoutsCopy = [...workouts];
	//      const workoutsReordered = arrayMoveImmutable(workoutsCopy, oldIndex, newIndex);
	//      workoutsAdapter.setAll(state.workouts, workoutsReordered);
	//  }
	// });
});

export default programReducer;
