import React from 'react';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const routes = {
	login: 'login',
	signup: 'signup',
	forgotPassword: 'forgot-password',
	dashboard: 'dashboard',
	accSetup: 'acount-setup',
	profile: 'profile',
	programs: 'programs',
	clients: 'clients',
	newProgram: 'new-program',
	week: 'week',
	basePrograms: 'base-programs',
	activePrograms: 'active-programs',
	pastPrograms: 'past-programs',
	futurePrograms: 'future-programs',
	coaches: 'coaches',
	info: 'info',
	billing: 'billing',
	newCoach: 'new-coach',
	schedule: 'schedule',
	availability: 'availability',
	myAccount: 'my-account',
	services: 'services',
	packages: 'packages',
	chat: 'chat',
	videoConference: 'video-conference',
	workoutTemplate: 'workout-template',
	habitTracking: 'habit-tracking',
	progressLogs: 'progress-logs',
} as const;

export const SIDE_BAR_WIDTH = 210;

export const smVariant = { navigation: 'drawer', navigationButton: true } as const;
export const mdVariant = { navigation: 'sidebar', navigationButton: false } as const;

export const topNavBarRef = React.createRef<HTMLDivElement>();
