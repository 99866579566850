const Table = {
	baseStyle: {
		th: {
			textTransform: 'none',
		},
	},
	defaultProps: {
		size: 'lg',
	},
	variants: {
		simple: {
			th: {
				color: 'black',
				pt: [5, 8, 10],
				pb: [4, 6, 7],
			},
		},
	},
};

export default Table;
