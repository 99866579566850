import { createAction, EntityId } from '@reduxjs/toolkit';

// import { ExerciseGroupType } from 'features/programs/constants';
import type { ClientExercise, ExerciseState, SetState } from 'features/programs/types';

export const resetNewProgram = createAction('program/resetNewProgram');

// export const resetProgramInfo = createAction('program/resetInfo');

export const addWeek = createAction('program/addWeek');

export const setWorkout = createAction<{ workoutData }>('program/setWorkout');

export const duplicateWeek = createAction<{ workoutId: EntityId; weekId: EntityId }>('program/duplicateWeek');
export const duplicateDay = createAction<{ dayId: EntityId }>('program/duplicateDay');

export const addDay = createAction<{ weekId: EntityId }>('program/addDay');

export const addBlock = createAction<{ dayId: EntityId }>('program/addBlock');

export const addNewExercise = createAction<{
	blockId: EntityId;
	clientExercise: ClientExercise;
}>('program/addNewExercise');

export const removeDay = createAction<{ dayId: EntityId }>('program/removeDay');

export const removeBlock = createAction<{ blockId: EntityId }>('program/removeBlock');

export const addRemoveExerciseSet = createAction<{
	exerciseId: EntityId;
	totalSets: number;
}>('program/addRemoveExerciseSet');

// export const addExercise = createAction('program/createAction');

export const addWeekEmpty = createAction<{ workoutId: EntityId }>('program/addWeekEmpty');

export const removeWeek = createAction<{ workoutId: EntityId; weekId: EntityId }>('program/removeWeek');

// export const updateWorkoutName = createAction<{ workoutId: EntityId }>('program/updateWorkoutName');

// export const addWorkoutSection = createAction<{ workoutId: EntityId }>('program/addWorkoutSection');

// export const removeWorkoutSection = createAction<{ workoutId: EntityId; sectionId: EntityId; exercisesfromForm }>(
//  'program/removeWorkoutSection',
// );

// export const updateSection = createAction<{ workoutId: EntityId; sectionId: EntityId; groupType: ExerciseGroupType }>(
//  'program/updateSection',
// );
export const updateBlockType = createAction<{
	blockId: EntityId;
	blockType: string;
}>('program/updateBlockType');
export const updateBlockRest = createAction<{
	// workoutId: EntityId;
	// weekId: EntityId;
	// dayId: EntityId;
	blockId: EntityId;
	rest: number;
}>('program/updateBlockRest');

export const updateSetData = createAction<{
	exerciseId: EntityId;
	setId: EntityId;
	setData: SetState;
}>('program/updatSetData');

export const updateExerciseRest = createAction<{
	workoutId: EntityId;
	weekId: EntityId;
	dayId: EntityId;
	blockId: EntityId;
	exerciseId: EntityId;
	rest: string;
}>('program/updateExerciseRest');

export const removeExerciseWorkout = createAction<{
	exerciseId: EntityId;
}>('program/removeExerciseWorkout');

export const updateBlockName = createAction<{
	blockId: EntityId;
	blockName: string;
}>('program/updateBlockName');

// export const addWorkoutExercise = createAction<{ templateId: EntityId; workoutId: EntityId; sectionId: EntityId }>(
//  'program/addWorkoutExercise',
// );

// export const removeWorkoutExercise = createAction<{
//  workoutId: EntityId;
//  sectionId: EntityId;
//  exerciseIndexBySection: number;
//  exercisesCurrentWorkout: ExerciseWorkout[];
// }>('program/removeWorkoutExercise');

// export const reorderWorkoutSections = createAction<{ weekId: EntityId; workoutId: EntityId; oldIndex: number; newIndex: number }>(
//  'program/reorderWorkoutSections',
// );

export const setAllExercises = createAction<Array<ExerciseState>>('program/setAllExercises');

export const removeAllExercises = createAction('program/clearAllExercises');

export const reorderExercises = createAction<{
	blockId: EntityId;
	oldIndex: number;
	newIndex: number;
}>('program/reorderExercises');

export const reorderBlocks = createAction<{
	dayId: EntityId;
	oldIndex: number;
	newIndex: number;
}>('program/reordreBlocks');

// export const reorderWorkoutExercises = createAction<{
//  workoutId: EntityId;
//  sectionId: EntityId;
//  oldIndex: number;
//  newIndex: number;
//  exercisesCurrentWorkout: ExerciseWorkout[];
// }>('program/reorderWorkoutExercises');

// export const addWorkout = createAction<{ weekId: EntityId }>('program/addWorkout');

// export const addWorkoutWithInfo = createAction<{
//  exercises: Exercise[];
//  // exercises: ExerciseWorkout & { groupType: ExerciseGroupType; groupLetter: string; thumbnailUrl: string } & [];
//  week: number;
// }>('program/addWorkoutWithInfo');

// export const removeWorkout = createAction<{ weekId: EntityId; workoutId: EntityId }>('program/removeWorkout');

// export const reorderWorkouts = createAction<{ oldIndex: number; newIndex: number; weekId: EntityId }>('program/reorderWorkouts');
