import React from 'react';
import { Icon } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import ReactSelect, { components, Props } from 'react-select';

import type { IconType } from 'react-icons';

export type SelectProps = Props & {
	options: Array<{ value: string; icon?: IconType; label: string }>;
};

const Select: React.FC<SelectProps> = React.forwardRef<ReactSelect, SelectProps>(({ components, styles, ...rest }, ref) => (
	<ReactSelect
		ref={ref}
		components={{ ...customComponents, ...components }}
		styles={{
			...customStyles,
			...styles,
			control: (...args) => ({ ...customStyles?.control?.apply(null, args), ...styles?.control?.apply(null, args) }),
		}}
		{...rest}
	/>
));

export default Select;

export const customComponents: SelectProps['components'] = {
	IndicatorSeparator: () => null,
	DropdownIndicator: ({ selectProps: { menuIsOpen } }) => (
		<Icon w={5} h={5} color="gray" as={menuIsOpen ? FiChevronUp : FiChevronDown} pointerEvents="none" />
	),
	Option: ({ children, data, ...props }) => (
		<components.Option {...props} data={data}>
			{data.icon && <Icon as={data.icon} mr={4} boxSize={[5, 6]} />}
			{children}
		</components.Option>
	),
};

export const customStyles: SelectProps['styles'] = {
	menu: (base) => ({
		...base,
		zIndex: 3,
		marginTop: 1,
		borderRadius: 0,
		marginBottom: 0,
		boxShadow: '0px 4px 11px rgba(132, 146, 166, 0.45)',
	}),
	menuList: (base) => ({
		...base,
		paddingBottom: 0,
		paddingTop: 0,
		'&::-webkit-scrollbar': {
			width: '6px',
		},
		'&::-webkit-scrollbar-track': {
			background: 'var(--koch-colors-brand-200)',
		},
		'&::-webkit-scrollbar-thumb': {
			background: 'var(--koch-colors-brand-500)',
			minHeight: 'var(--koch-sizes-12)',
		},
	}),
	control: (base) => ({
		...base,
		borderRadius: 'var(--koch-radii-md)',
		height: 'var(--koch-sizes-16)',
		fontFamily: 'var(--koch-fonts-secondary)',
		paddingLeft: 'var(--koch-space-2)',
		paddingRight: 'var(--koch-space-4)',
	}),
	option: (base, { isDisabled, isFocused, isSelected }) => ({
		...base,
		backgroundColor: isDisabled
			? undefined
			: isSelected
			? 'var(--koch-colors-gray-50)'
			: isFocused
			? 'var(--koch-colors-gray-50)'
			: undefined,
		color: isSelected ? 'black' : 'var(--koch-colors-gray-500)',
		borderBottom: 'var(--koch-colors-gray-150)',
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
		lineHeight: 'var(--koch-sizes-12)',
		fontSize: 'var(--koch-fontSizes-md)',
		fontWeight: 'normal',
		fontStyle: 'normal',
		paddingLeft: 'var(--koch-space-6)',
	}),
};
