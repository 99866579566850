import React from 'react';
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import type { IconType } from 'react-icons';

type NavItemProps = NavLinkProps & {
	icon: IconType;
	children: React.ReactText;
};

const NavItem = ({ icon, children, ...rest }: NavItemProps) => (
	<Flex
		direction="column"
		borderRadius="lg"
		mb={3}
		sx={{
			'.active': {
				color: '#000',
				background: '#EDFF82',
				'& img': {
					filter: 'brightness(0)', // Set filter brightness to 0 for black color
				},
			},
		}}>
		<Link as={NavLink} textDecoration="none" width="100%" color="#fff" py={2} {...rest}>
			<Box display="flex" alignItems="center" justifyContent="flex-start" m={2} mt={1} mb={1}>
				{icon && <Image src={icon} w={18} alt="icons" />}
				<Text fontFamily="Actay" fontWeight="bold" fontSize="sm" pl={3} pt={1}>
					{children}
				</Text>
			</Box>
		</Link>
	</Flex>
);

export default NavItem;
