import { extendTheme, ThemeConfig } from '@chakra-ui/react';

import colors from './colors';
import Button from './components/button';
import Input from './components/input';
import Link from './components/link';
import Select from './components/select';
import Table from './components/table';
import Tabs from './components/tabs';

const config: ThemeConfig = {
	cssVarPrefix: 'koch',
	useSystemColorMode: false,
};

const textStyles = {
	h1: {
		// you can also use responsive styles
		fontSize: ['48px', '72px'],
		fontWeight: 'bold',
		lineHeight: '110%',
		letterSpacing: '-2%',
	},
	h2: {
		fontSize: ['36px', '48px'],
		fontWeight: 'semibold',
		lineHeight: '110%',
		letterSpacing: '-1%',
	},
};

const fonts = {
	heading: 'Rubik',
	body: 'Rubik',
	secondary: 'Karla',
};

const overrides = {
	colors,
	config,
	textStyles,
	fonts,
	components: {
		Button,
		Input,
		Link,
		Tabs,
		Select,
		Table,
	},
};

const theme = extendTheme(overrides);

// if (process.env.NODE_ENV === 'development') console.log('THEME', theme);

export default theme;
