import React from 'react';
import ReactDOM from 'react-dom';

import { topNavBarRef } from 'routes/constants';

type Props = {
	children: React.ReactNode;
};

function TopNavBarPortal({ children }: Props) {
	const portalEl = React.useMemo(() => {
		const el = document.createElement('div');
		el.style.maxWidth = '100%';
		el.style.flex = '1';
		return el;
	}, []);

	React.useLayoutEffect(() => {
		const node = topNavBarRef.current;
		if (!node) return;

		if (node.firstElementChild) {
			node.replaceChild(portalEl, node.firstElementChild);
		} else {
			node.appendChild(portalEl);
		}

		return () => {
			try {
				node.removeChild(portalEl);
			} catch (error) {
				if (process.env.NODE_ENV === 'development') console.warn(error);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return ReactDOM.createPortal(children, portalEl);
}

export default TopNavBarPortal;
