import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';

import { UserRole } from 'shared/constants';

import type { RootState } from 'shared/store';

export const authSelector = (state: RootState) => state.auth;

export const selectPayloadUserToken = createSelector(authSelector, (auth) => auth.payloadData);

export const selectUserToken = createDraftSafeSelector(authSelector, (auth) => auth?.userToken);

export const selectUserRole = createSelector(selectPayloadUserToken, (payloadData) => (payloadData ? payloadData.role : null));

export const isAdminSelector = createSelector(selectUserRole, (role) => role === UserRole.ADMIN);

export const selectAvatar = createSelector(authSelector, (auth) => auth.avatar);

export const selectUserEmail = createSelector(selectPayloadUserToken, (payloadData) => (payloadData ? payloadData.email : null));
