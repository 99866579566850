import { __DEV__ } from '@chakra-ui/utils';
import * as yup from 'yup';

import config from 'shared/constants/config';

const ENABLE_AUTOFILL_DEV = __DEV__ && config.form.enableAutofillDev && 1;

export const formKeys = {
	newCoach: {
		firstName: 'firstName',
		lastName: 'lastName',
		email: 'email',
		phone: 'phone',
		country: 'country',
	},
	coachProfile: {
		firstName: 'firstName',
		lastName: 'lastName',
		email: 'email',
		phone: 'phone',
	},
} as const;

export const forms = {
	newCoach: {
		get initialValues() {
			return {
				firstName: __DEV__ && ENABLE_AUTOFILL_DEV ? 'John' : '',
				lastName: __DEV__ && ENABLE_AUTOFILL_DEV ? 'Smith' : '',
				email: __DEV__ && ENABLE_AUTOFILL_DEV ? `jsmith+${(Math.random() * 1000).toFixed(0)}@koch.com` : '',
				phone: __DEV__ && ENABLE_AUTOFILL_DEV ? '8888888888' : undefined,
			};
		},
		labels: {
			firstName: 'First Name',
			lastName: 'Last name',
			email: 'Email',
			phone: 'Phone Number',
		},
		placeholders: {
			firstName: 'Enter name',
			lastName: 'Enter last name',
			email: 'Enter email',
			phone: 'Enter phone number',
		},
		schema: yup.object({
			firstName: yup.string().trim().required('First name is a required field'),
			lastName: yup.string().trim().required('Last name is a required field'),
			email: yup.string().email().trim().required('Email address is required').lowercase(),
			phone: yup.string().trim().required('Phone number is a required field'),
		}),
	},
	coachProfile: {
		get initialValues() {
			return {
				[formKeys.coachProfile.firstName]: '',
				[formKeys.coachProfile.lastName]: '',
				[formKeys.coachProfile.email]: '',
				[formKeys.coachProfile.phone]: '',
			};
		},
		labels: {
			[formKeys.coachProfile.firstName]: 'First Name',
			[formKeys.coachProfile.lastName]: 'Last name',
			[formKeys.coachProfile.email]: 'Email',
			[formKeys.coachProfile.phone]: 'Phone Number',
		},
		placeholders: {
			[formKeys.coachProfile.firstName]: 'Enter name',
			[formKeys.coachProfile.lastName]: 'Enter last name',
			[formKeys.coachProfile.email]: 'Enter email',
			[formKeys.coachProfile.phone]: 'Enter phone number',
		},
		schema: yup.object({
			[formKeys.coachProfile.firstName]: yup.string().trim().required('First name is a required field'),
			[formKeys.coachProfile.lastName]: yup.string().trim().required('Last name is a required field'),
			[formKeys.coachProfile.email]: yup.string().email().trim().required('Email address is required').lowercase(),
			[formKeys.coachProfile.phone]: yup.string().trim().required('Phone number is a required field'),
		}),
	},
};
