import React from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

import { getFormControlProps } from 'shared/helpers/form';

import Select, { SelectProps } from '../Select';

import type { FieldProps } from './types';

type Props = FieldProps<SelectProps>;

const FieldSelect: React.FC<Props> = (props) => {
	const { control, name, defaultValue, label, children, rules, onBlur, onChange, shouldUnregister, ...rest } = props;
	const {
		field,
		fieldState: { error },
		formState: { isSubmitting },
	} = useController({ control, name, defaultValue, rules, shouldUnregister });
	const isDisabled = isSubmitting;

	const formControlProps = getFormControlProps(rest, error);

	const handleBlur = (ev: React.FocusEvent<HTMLElement>) => {
		field?.onBlur();
		onBlur?.(ev);
	};

	const handleChange: SelectProps['onChange'] = (value, action) => {
		field?.onChange(value, action);
		onChange?.(value, action);
	};
	const isInvalid = !!error?.message;

	return (
		<FormControl {...formControlProps}>
			{label && <FormLabel htmlFor={name}>{label}</FormLabel>}
			<Select
				styles={{
					control: (_, { isFocused }) =>
						isInvalid && !isFocused
							? {
									borderColor: 'var(--koch-colors-red-500)',
									borderWidth: '2px',
							  }
							: {},
				}}
				isDisabled={isDisabled}
				{...rest}
				{...field}
				onBlur={handleBlur}
				onChange={handleChange}
			/>
			{error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
			{children}
		</FormControl>
	);
};

export default React.memo(FieldSelect, isEqual);
