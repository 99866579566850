import React from 'react';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	forwardRef,
	Input,
	InputGroup,
	InputProps,
	useMergeRefs,
} from '@chakra-ui/react';
import { RefCallBack, useController } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

import { getFormControlProps, getInputProps } from 'shared/helpers/form';

import type { FieldProps } from './types';

type Props = FieldProps<InputProps>;

const FieldInput = forwardRef<Props, 'input'>((props, ref) => {
	const { control, name, defaultValue, label, children, rules, shouldUnregister, ...rest } = props;
	const {
		field,
		fieldState: { error },
		formState: { isSubmitting },
	} = useController({ control, name, defaultValue, rules, shouldUnregister });
	const refs = useMergeRefs(ref, field.ref);
	const isDisabled = isSubmitting;

	const formControlProps = getFormControlProps(rest, error);
	const inputProps = { ...getInputProps(rest, { ...field, ref: refs as RefCallBack }), isDisabled };
	// comment
	return (
		<FormControl {...formControlProps}>
			{label && (
				<FormLabel htmlFor={name} fontSize={props.size === 'md' ? 'sm' : props.size} fontFamily="Actay Wide">
					{label}
				</FormLabel>
			)}
			{children ? (
				<InputGroup size={props.size}>
					<Input {...inputProps} borderRadius="15px" />
					{children}
				</InputGroup>
			) : (
				<Input {...inputProps} borderRadius="15px" />
			)}
			{error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
		</FormControl>
	);
});

export default React.memo(FieldInput, isEqual) as typeof FieldInput;
