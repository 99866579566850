import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import ScrollToTop from 'routes/components/ScrollToTop';
import RootRoutes from 'routes/containers/Root.routes';
import PageLoading from 'shared/components/PageLoading';
import { persistor, store } from 'shared/store';
import ThemeProvider from 'theme/ThemeProvider';

const Root = () => (
	<ThemeProvider>
		<PersistGate persistor={persistor} loading={<PageLoading />}>
			<StoreProvider store={store}>
				<BrowserRouter>
					<React.Suspense fallback={<PageLoading />}>
						<RootRoutes />
						<ScrollToTop />
					</React.Suspense>
				</BrowserRouter>
			</StoreProvider>
		</PersistGate>
	</ThemeProvider>
);

export default Root;
