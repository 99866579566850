import React from 'react';
import { Box, Image, VStack } from '@chakra-ui/react';
import { matchPath, NavLinkProps } from 'react-router-dom';

import kochLogo from 'assets/images/koch_logo_new.png';
import BillingLogo from 'assets/images/navbar/billing.png';
import ClientsLogo from 'assets/images/navbar/clients.png';
import DashboardLogo from 'assets/images/navbar/dashboard.png';
import PackagesLogo from 'assets/images/navbar/packages.png';
import ScheduleLogo from 'assets/images/navbar/schedule.png';
import ServicesLogo from 'assets/images/navbar/services.png';
import WorkoutLogo from 'assets/images/navbar/workout.png';
import { selectUserRole } from 'features/auth/store/selectors';
import { routes } from 'routes/constants';
import { UserRole } from 'shared/constants';
import { useAppSelector } from 'shared/store/hooks';

import NavItem from './NavItem';

interface SideBarItemProps extends NavLinkProps {
	title: string;
	icon: any;
	roles: Array<UserRole>;
}

type Props = {
	onClick: () => void;
};

const LATERAL_BAR: Array<SideBarItemProps> = [
	{
		title: 'Dashboard',
		icon: DashboardLogo,
		to: `/${routes.dashboard}`,
		roles: [UserRole.COACH, UserRole.ADMIN],
	},
	{
		title: 'Services',
		icon: ServicesLogo,
		to: `/${routes.services}`,
		roles: [UserRole.COACH, UserRole.ADMIN],
	},
	{
		title: 'Packages',
		icon: PackagesLogo,
		to: `/${routes.packages}`,
		roles: [UserRole.COACH, UserRole.ADMIN],
	},
	{
		title: 'Workout Builder',
		icon: WorkoutLogo,
		to: `/${routes.programs}`,
		isActive: (match, location) => !!match || !!matchPath(location.pathname, { path: [`/${routes.newProgram}`] }),
		roles: [UserRole.COACH, UserRole.ADMIN],
	},
	// {
	// 	title: 'Coaches',
	// 	icon: UilUsersAlt,
	// 	to: `/${routes.coaches}`,
	// 	isActive: (match, location) => !!match || !!matchPath(location.pathname, { path: [`/${routes.newCoach}`] }),
	// 	roles: [UserRole.ADMIN],
	// },
	{ title: 'Clients', icon: ClientsLogo, to: `/${routes.clients}`, roles: [UserRole.COACH, UserRole.ADMIN] },
	{ title: 'Billing', icon: BillingLogo, to: `/${routes.billing}`, roles: [UserRole.ADMIN] },
	{
		title: 'Schedule',
		icon: ScheduleLogo,
		to: `/${routes.schedule}`,
		roles: [UserRole.COACH, UserRole.ADMIN],
	},
];

const SidebarContent = ({ onClick }: Props) => {
	const role = useAppSelector(selectUserRole);

	return (
		<VStack flex="1" h={['auto', 'full']}>
			<Box align="center" mb={[5, 10]}>
				<Image src={kochLogo} alt="Koch Logo" w={[30, 55]} />
			</Box>
			<Box>
				{React.Children.toArray(
					LATERAL_BAR.filter((item) => role && item.roles.includes(role)).map((item) => (
						<NavItem key={item.title} {...item} onClick={onClick} style={{ width: '160px', borderRadius: '8px' }}>
							{item.title}
						</NavItem>
					)),
				)}
			</Box>
		</VStack>
	);
};

export default SidebarContent;
