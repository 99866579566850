const Button = {
	baseStyle: {
		letterSpacing: '0.1em',
		fontWeight: 'semibold',
		borderRadius: '4px',
	},
	defaultProps: {
		size: 'lg',
	},
};

export default Button;
