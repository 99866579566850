import { createAction } from '@reduxjs/toolkit';

export const signOut = createAction('auth/signOut');

export const setCredentials = createAction<{ token: string; avatar: string | null }>('auth/setCredentials');

export const setCredentialsGoogle = createAction<{ credential: string }>('auth/setCredentialsGoogle');

export const signUp = createAction<{ message: string }>('auth/signUp');

export const setAvatar = createAction<{ avatar: string }>('auth/setAvatar');
