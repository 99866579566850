import React from 'react';
import { Avatar, Flex, HStack, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import Uildown from '@iconscout/react-unicons/icons/uil-angle-down';

import { useLazyLogoutQuery } from 'features/auth/services';
import { selectAvatar } from 'features/auth/store/selectors';
import { routes, topNavBarRef } from 'routes/constants';
import { useAppSelector } from 'shared/store/hooks';

type Props = {
	showSidebarButton?: boolean;
	onShowSidebar?: () => void;
};

const Header = ({ showSidebarButton, onShowSidebar }: Props) => {
	const history = useHistory();
	const avatar = useAppSelector(selectAvatar);
	const [logout] = useLazyLogoutQuery();
	const handleViewProfile = () => history.push(`/${routes.myAccount}`);
	const handleClickChat = () => history.push(`/${routes.chat}`);
	const handleLogout = () => logout();
	return (
		<Flex
			as="header"
			pos="sticky"
			bg="white"
			zIndex="3"
			color="gray.400"
			top="0"
			px={[5, 6, 8]}
			minH={[28, 36]}
			borderBottom="1px solid black">
			{/* {showSidebarButton && (
				<IconButton
					icon={<Icon w={8} h={8} as={FiMenu} />}
					colorScheme="blackAlpha"
					variant="ghost"
					aria-label="show menu side bar"
					onClick={onShowSidebar}
				/>
			)} */}
			<Flex ref={topNavBarRef} flex="1" maxW="100%" />
			<HStack pos="absolute" top={[2, 5]} right={[5, 0]} spacing={{ base: '0', md: '6' }}>
				<Flex alignItems={['flex-start', 'center']} pr={[0, 8, 10]}>
					<HStack py={[0, 2]} cursor="pointer">
						<div>
							<IoPaperPlaneOutline size="24px" color="#333333" style={{ marginRight: '2vh' }} onClick={handleClickChat} />
						</div>
						<Menu>
							<MenuButton>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div>
										<Avatar src={avatar} boxSize="40px" borderWidth="3px" borderColor="white" boxShadow="lg" />
									</div>
									<div>
										<Uildown size="24px" color="#333333" style={{ marginLeft: '2vh' }} />
									</div>
								</div>
							</MenuButton>
							<MenuList fontFamily="Actay">
								<MenuGroup>
									<MenuItem color="#333" onClick={handleViewProfile}>
										My Account
									</MenuItem>
									{/* <MenuItem color="#333">Settings </MenuItem> */}
									<MenuItem color="#333" as="a" href="https://www.mykoch.app/coachform" target="_blank">
										Feedback{' '}
									</MenuItem>
									<MenuItem color="#333" as="a" href="https://www.mykoch.app/coach-tutorial" target="_blank">
										Help{' '}
									</MenuItem>
									<MenuItem color="#333" onClick={handleLogout}>
										Log Out
									</MenuItem>
								</MenuGroup>
								<MenuDivider />
								<MenuGroup>
									<MenuItem color="#333" as="a" href="https://mykoch.app" target="_blank">
										Download App
									</MenuItem>
								</MenuGroup>
							</MenuList>
						</Menu>
					</HStack>
				</Flex>
			</HStack>
		</Flex>
	);
};

export default Header;
