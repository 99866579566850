import '@fontsource/rubik';
import '@fontsource/karla';
import '../shared/fonty.css';

import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import theme from './theme';

type Props = { children: React.ReactNode };

const ThemeProvider = (props: Props) => <ChakraProvider theme={theme} {...props} />;

export default ThemeProvider;
