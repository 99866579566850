import React from 'react';
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import type { IconType } from 'react-icons';

type NavItemProps = NavLinkProps & {
	icon: IconType;
	children: React.ReactText;
};

const BottomNavItem = ({ icon, children, ...rest }: NavItemProps) => (
	<Flex
		direction="row"
		borderRadius="lg"
		sx={{
			'.active': {
				color: '#000',
				background: '#EDFF82',
				'& img': {
					filter: 'brightness(0)', // Set filter brightness to 0 for black color
				},
			},
		}}>
		<Link as={NavLink} textDecoration="none" color="#333333" w="100%" p={2} {...rest}>
			<Box align="center" justify="center">
				{icon && <Image src={icon} w={18} alt="icons" filter="brightness(0.5)" />}
				<Text
					pt={1}
					fontFamily="Karla"
					fontWeight="bold"
					fontSize={['sm', 'sm']}
					whiteSpace="nowrap"
					overflow="hidden"
					textOverflow="ellipsis">
					{children}
				</Text>
			</Box>
		</Link>
	</Flex>
);

export default BottomNavItem;
