import { __DEV__ } from '@chakra-ui/utils';

let host = __DEV__ && 0 ? process.env.REACT_APP_AETHOS_MOCK_API_URL : process.env.REACT_APP_AETHOS_API_URL;
// const version = 'V' + process.env.REACT_APP_AETHOS_API_VERSION ?? 0;
const version = 'V1';

if (host?.includes('dev') && Number(window.localStorage.getItem('mock')) && process.env.REACT_APP_AETHOS_MOCK_API_URL) {
	host = process.env.REACT_APP_AETHOS_MOCK_API_URL;
}

const mock = host?.includes('mock');

const enableAutofillDev = __DEV__ && Boolean(1);

const config = {
	api: {
		baseURL: `${host}/${version}`,
		mock: mock,
	},
	form: {
		enableAutofillDev,
		debugCredentials: {
			email: __DEV__ ? process.env.REACT_APP_AETHOS_DEBUG_USER_EMAIL : undefined,
			password: __DEV__ ? process.env.REACT_APP_AETHOS_DEBUG_USER_PASSWORD : undefined,
		},
	},
};

export default config;
