import { AddClientRequest, SearchCoachesRequest, SearchCoachesResponse } from 'features/clients/types';
import ApiService from 'shared/services/api.service';

import type {
	AssignClientRequest,
	changeStatusCoachRequest,
	CreateCoachRequest,
	CreateCoachResponse,
	ListClientByCoachNameRequest,
	ListClientByCoachNameResponse,
	ListCoachesRequest,
	ListCoachesResponse,
	ProfileCoachRequest,
	ProfileCoachResponse,
	uptadeProfileCoachRequest,
} from '../types';

const api = ApiService.injectEndpoints({
	endpoints: (builder) => ({
		listCoaches: builder.query<ListCoachesResponse, ListCoachesRequest>({
			query: ({ page = 1, limit = 1, search }) => ({
				url: 'coaches',
				params: {
					page,
					limit,
					search,
				},
			}),
		}),
		createCoach: builder.mutation<CreateCoachResponse, CreateCoachRequest>({
			query: (data) => ({
				url: 'coach',
				method: 'POST',
				body: data,
			}),
		}),
		assignCoach: builder.mutation<unknown, AssignClientRequest>({
			query: ({ coachId, clientId }) => ({
				url: 'coach/assign',
				method: 'POST',
				params: {
					clientId,
					coachId,
				},
			}),
		}),
		listClientByCoachId: builder.query<ListClientByCoachNameResponse, ListClientByCoachNameRequest>({
			query: ({ page = 1, limit = 1, id }) => ({
				url: 'coach/clients',
				params: {
					coachId: id,
					page,
					limit,
				},
			}),
		}),
		searchCoaches: builder.query<SearchCoachesResponse, SearchCoachesRequest>({
			query: ({ fullName }) => ({
				url: 'coaches/search',
				params: {
					fullName: encodeURIComponent(fullName),
				},
			}),
		}),
		getProfileCoach: builder.query<ProfileCoachResponse['profile'] | undefined, ProfileCoachRequest>({
			query: ({ coachId }) => ({
				url: 'coach/profile',
				params: {
					id: coachId,
				},
			}),
			transformResponse: (response: ProfileCoachResponse) => {
				if (!response) {
					return;
				}
				const { profile } = response;
				return profile;
			},
		}),
		updateCoachProfile: builder.mutation<void, uptadeProfileCoachRequest>({
			query: ({ coachId, data }) => ({
				url: 'coach/profile',
				method: 'PATCH',
				body: data,
				params: {
					id: coachId,
				},
			}),
		}),
		changeStatusCoach: builder.mutation<{ success: boolean }, changeStatusCoachRequest>({
			query: ({ coach }) => ({
				url: 'coach/status',
				method: 'PATCH',
				body: {
					coach,
				},
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useListCoachesQuery,
	useCreateCoachMutation,
	useAssignCoachMutation,
	useListClientByCoachIdQuery,
	useSearchCoachesQuery,
	useGetProfileCoachQuery,
	useUpdateCoachProfileMutation,
	useChangeStatusCoachMutation,
} = api;

export default api;
