import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { routerMiddleware } from 'connected-react-router';
import { FLUSH, PAUSE, PERSIST, PersistConfig, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { history } from 'routes/constants';
import ApiService from 'shared/services/api.service';
import StorageService from 'shared/services/storage.service';

import rtkQueryErrorLogger from './middlewares/rtkQueryErrorLogger';
import rootReducer from './rootReducer';

const persistConfig: PersistConfig<any> = {
	key: 'root',
	version: 1,
	storage: StorageService,
	blacklist: [ApiService.reducerPath, 'auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer) as typeof rootReducer;

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => {
		const middleware = getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		});
		middleware.push(ApiService.middleware, rtkQueryErrorLogger, routerMiddleware(history));
		return middleware;
	},
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export default store;
