import React from 'react';
import { Checkbox, CheckboxProps, FormControl, FormErrorMessage, FormLabel, forwardRef, useMergeRefs } from '@chakra-ui/react';
import { RefCallBack, useController } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

import { getFormControlProps, getInputProps } from 'shared/helpers/form';

import type { FieldProps } from './types';

type Props = FieldProps<CheckboxProps>;

const FieldCheckbox = forwardRef<Props, 'input'>((props, ref) => {
	const { control, name, defaultValue, label, children, rules, value, shouldUnregister, ...rest } = props;
	const {
		field,
		fieldState: { error },
		formState: { isSubmitting },
	} = useController({ control, name, defaultValue, rules, shouldUnregister });
	const refs = useMergeRefs(ref, field.ref);
	const isDisabled = isSubmitting;

	const formControlProps = getFormControlProps(rest, error);
	const inputProps = { ...getInputProps(rest, { ...field, ref: refs as RefCallBack }), isDisabled };

	return (
		<FormControl {...formControlProps}>
			{label && <FormLabel htmlFor={name}>{label}</FormLabel>}
			<Checkbox {...inputProps} isChecked={field.value} value={value} children={children} />
			{error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
		</FormControl>
	);
});

export default React.memo(FieldCheckbox, isEqual);
