import React from 'react';
import { Redirect } from 'react-router-dom';

import { selectUserToken } from 'features/auth/store/selectors';
import { routes } from 'routes/constants';
import { useAppSelector } from 'shared/store/hooks';

function NewRootRedirect() {
	const userToken = useAppSelector(selectUserToken);

	return <Redirect to={userToken ? `/${routes.myAccount}` : `/${routes.login}`} />;
}

export default NewRootRedirect;
