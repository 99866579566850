export const DEFAULT_ERROR_MESSAGE = "Something didn't go right but let's try again.";

export enum UserRole {
	CLIENT = 1,
	COACH = 2,
	ADMIN = 3,
}

export enum MembershipStatus {
	INACTIVE = 'INACTIVE',
	ACTIVE = 'ACTIVE',
}

export enum CoachStatus {
	INACTIVE = 'INACTIVE',
	ACTIVE = 'ACTIVE',
}
