import React from 'react';
import { Box, Heading, HStack, Icon, Image, Tab, TabList, useBreakpointValue, VStack } from '@chakra-ui/react';
import { Link as ReactRouterLink, useHistory } from 'react-router-dom';

import kochLogo from 'assets/images/koch_logo_secondary.png';
import TopNavBarPortal from 'routes/components/TopNavBarPortal';
import { mdVariant, routes, smVariant } from 'routes/constants';

import BackButton from '../../shared/components/BackButton';

import type { LocationDescriptor } from 'history';
import type { NavItemTab } from 'routes/types';

type Props = {
	goBackTo?: LocationDescriptor;
	title?: string;
	subHeader?: React.ReactElement;
	tabs?: Array<NavItemTab | React.ReactElement>;
};

const serializeTabs = (tabs: Props['tabs']) =>
	tabs?.map((item) => (React.isValidElement(item) ? item.key : item.id || item.label)).join();

const TopNavBar = ({ title, tabs, goBackTo, subHeader }: Props) => {
	const history = useHistory();
	const variants = useBreakpointValue({ base: smVariant, md: mdVariant });
	const handleimageclick = () => {
		history.push(`/${routes.dashboard}`);
	};

	return (
		<TopNavBarPortal>
			<VStack flex="1" h="full" align="flex-start" justify={['space-evenly', 'space-between']} pt={[1, 9, 10]} spacing={2}>
				{variants?.navigation == 'drawer' && (
					<Box align="start" m={2}>
						<Image src={kochLogo} alt="Koch Logo" w={[30, 63]} onClick={handleimageclick} />
					</Box>
				)}
				{(title || goBackTo) && (
					<HStack maxW="90%">
						{goBackTo && <BackButton to={goBackTo} />}
						{title && (
							<Heading
								fontSize={['sm', 'xl']}
								color="black"
								noOfLines={2}
								fontFamily="Actay Wide"
								textTransform="uppercase"
								letterSpacing="2.40px">
								{title}
								{subHeader}
							</Heading>
						)}
					</HStack>
				)}
				{Array.isArray(tabs) && tabs.length > 0 && (
					<Box w="full">
						<TabList
							fontFamily="Actay Wide"
							overflowX="auto"
							overflowY="hidden"
							whiteSpace="nowrap"
							px={1}
							// p={0}
							mb={2}
							sx={{
								scrollbarWidth: 'thin',
								scrollBehavior: 'auto',
								'&::-webkit-scrollbar': {
									height: '0px',
								},
							}}>
							<HStack spacing={0} mr={4}>
								{React.Children.toArray(
									tabs.map((item, index) =>
										React.isValidElement(item) ? (
											item
										) : (
											<Tab
												isDisabled={item?.isDisabled}
												key={item.id || item.label}
												as={ReactRouterLink}
												to={item.to}
												minW="100px"
												fontSize={['xs', 'md']}
												borderLeft={index != 0 ? '1px solid #BABABA' : null}>
												{item.leftIcon && <Icon mr={3} as={item.leftIcon} onClick={item.onClickLeftIcon} />}
												{item.label}
												{item.rightIcon && <Icon ml={3} as={item.rightIcon} onClick={item.onClickRightIcon} color="red.500" />}
											</Tab>
										),
									),
								)}
							</HStack>
						</TabList>
					</Box>
				)}
			</VStack>
		</TopNavBarPortal>
	);
};

export default React.memo(
	TopNavBar,
	(prevProps, nextProps) =>
		prevProps.title === nextProps.title &&
		prevProps.goBackTo === nextProps.goBackTo &&
		serializeTabs(prevProps.tabs) === serializeTabs(nextProps.tabs),
);
