import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { selectUserToken } from 'features/auth/store/selectors';
import { routes } from 'routes/constants';
import { useAppSelector } from 'shared/store/hooks';

type Props = React.ComponentPropsWithoutRef<typeof Route>;

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function ProtectedAuthRoute({ children, component: Component, ...rest }: Props) {
	const userToken = useAppSelector(selectUserToken);
	return (
		<Route
			{...rest}
			render={(props) =>
				userToken ? (
					<Redirect
						to={{
							pathname: `/${routes.dashboard}`,
							state: { from: props.location },
						}}
					/>
				) : Component ? (
					<Component {...props} />
				) : (
					children
				)
			}
		/>
	);
}

export default ProtectedAuthRoute;
