import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { selectUserRole } from 'features/auth/store/selectors';
import NewCoachPage from 'features/coaches/pages/NewCoach.page';
import MainLayout from 'routes/components/MainLayout';
import ProtectedAuthRoute from 'routes/components/ProtectedAuthRoute';
import { UserRole } from 'shared/constants';
import { useAppSelector } from 'shared/store/hooks';

import PrivateRoute from '../components/PrivateRoute';
import { routes } from '../constants';
import NewRootRedirect from './NewRootRedirect';
import RootRedirect from './RootRedirect';
const AccountSetupRoutes = React.lazy(() => import('./AccountSetup.routes'));

const LoginPage = React.lazy(() => import('features/auth/pages/Login.page'));
const ForgotPasswordPage = React.lazy(() => import('features/auth/pages/ForgotPassword.page'));
const SignupPage = React.lazy(() => import('features/auth/pages/Signup.page'));
const MyAccountRoutes = React.lazy(() => import('./MyAccount.routes'));

const VerifyComponent = React.lazy(() => import('features/auth/components/Verifycomponent.component'));
const Verificationkey = React.lazy(() => import('features/auth/components/Verificationkey.component'));
const DashboardRoutes = React.lazy(() => import('./Dashboard.routes'));
const ScheduleRoutes = React.lazy(() => import('./Schedule.routes'));

const ProfileClientPage = React.lazy(() => import('features/clients/pages/Profile.page'));
const HabitTrackingPage = React.lazy(() => import('features/clients/pages/HabitTracking.page'));
const ProgressLogsPage = React.lazy(() => import('features/clients/pages/ProgressLogs.page'));
const VideoConference = React.lazy(() => import('features/chat/pages/VideoConference.page'));

const ClientsRoutes = React.lazy(() => import('./Clients.routes'));
const ChatRoutes = React.lazy(() => import('./Chat.routes'));
const ProgramsRoutes = React.lazy(() => import('./Programs.routes'));
const NewProgramRoutes = React.lazy(() => import('./NewProgram.routes'));
const ProgramInfo = React.lazy(() => import('./ProgramInfo.routes'));
const ProgramTemplateInfoRoutes = React.lazy(() => import('./ProgramTemplateInfo.routes'));
const CoachesRoutes = React.lazy(() => import('./Coaches.routes'));
const BillingRoutes = React.lazy(() => import('./Billing.routes'));
const CoachInfo = React.lazy(() => import('./CoachInfo.routes'));
const Services = React.lazy(() => import('./Services.routes'));
const Packages = React.lazy(() => import('./Packages.routes'));

const RootRoutes = () => {
	const role = useAppSelector(selectUserRole);
	const location = useLocation();
	const id = window.localStorage.getItem('coach_type');
	const kycStatus = window.localStorage.getItem('kycStatus');
	const coach_type = id?.replaceAll('"', '');

	if (coach_type == 'DEFAULT') {
		return (
			<Switch>
				<Route path="/" exact component={RootRedirect} />
				<Route path="/verify/:tokenid" exact component={VerifyComponent} />
				{/* <Route path="/dashboard" exact component={Dashboard} /> */}
				<Route path="/verificationkey/:tokenid" exact component={Verificationkey} />
				<ProtectedAuthRoute path={`/${routes.login}`} component={LoginPage} />
				<ProtectedAuthRoute path={`/${routes.forgotPassword}`} component={ForgotPasswordPage} />
				<ProtectedAuthRoute path={`/${routes.signup}`} component={SignupPage} />
			</Switch>
		);
	}

	if (coach_type == 'VERIFIED' && kycStatus == 'false') {
		return (
			<Switch>
				<Route path="/" exact component={NewRootRedirect} />
				<Route path="/verify/:tokenid" exact component={VerifyComponent} />
				{/* <Route path="/dashboard" exact component={Dashboard} /> */}
				<Route path="/verificationkey/:tokenid" exact component={Verificationkey} />
				<ProtectedAuthRoute path={`/${routes.login}`} component={LoginPage} />
				<ProtectedAuthRoute path={`/${routes.forgotPassword}`} component={ForgotPasswordPage} />
				<ProtectedAuthRoute path={`/${routes.signup}`} component={SignupPage} />
				<MainLayout>
					<Switch>
						<PrivateRoute path={`/${routes.dashboard}`} component={DashboardRoutes} exact />
						<PrivateRoute path={`/${routes.myAccount}`} component={MyAccountRoutes} exact />
						<Redirect from="*" to="/" />
					</Switch>
				</MainLayout>
			</Switch>
		);
	}

	return (
		<Switch>
			<Route path="/" exact component={RootRedirect} />
			<Route path="/verify/:tokenid" exact component={VerifyComponent} />
			{/* <Route path="/dashboard" exact component={Dashboard} /> */}
			<Route path="/verificationkey/:tokenid" exact component={Verificationkey} />
			<ProtectedAuthRoute path={`/${routes.login}`} component={LoginPage} />
			<ProtectedAuthRoute path={`/${routes.forgotPassword}`} component={ForgotPasswordPage} />
			<ProtectedAuthRoute path={`/${routes.signup}`} component={SignupPage} />
			<MainLayout>
				<Switch>
					{role === UserRole.ADMIN && (
						<Redirect
							from={`/${routes.programs}`}
							to={{ pathname: `/${routes.programs}/${routes.basePrograms}`, state: location.state }}
							exact
						/>
					)}
					<PrivateRoute
						path={[
							`/${routes.programs}/${routes.activePrograms}`,
							`/${routes.programs}/${routes.futurePrograms}`,
							`/${routes.programs}/${routes.pastPrograms}`,
							`/${routes.programs}/${routes.basePrograms}`,
							`/${routes.programs}/${routes.workoutTemplate}`,
							`/${routes.programs}`,
						]}
						component={ProgramsRoutes}
						exact
					/>
					<PrivateRoute path={`/${routes.dashboard}`} component={DashboardRoutes} exact />
					<PrivateRoute path={`/${routes.myAccount}`} component={MyAccountRoutes} exact />
					<PrivateRoute path={[`/${routes.services}`, `/${routes.services}/:type`]} component={Services} exact />
					<PrivateRoute path={[`/${routes.packages}`, `/${routes.packages}/templates`]} component={Packages} exact />
					<PrivateRoute path={`/${routes.dashboard}/${routes.accSetup}`} component={AccountSetupRoutes} exact />
					<PrivateRoute path={`/${routes.schedule}`} component={ScheduleRoutes} exact />
					<PrivateRoute path={`/${routes.chat}`} component={ChatRoutes} exact />
					<PrivateRoute path={`/${routes.videoConference}`} component={VideoConference} exact />
					<PrivateRoute
						path={[`/${routes.programs}/${routes.info}/:id`, `/${routes.programs}/${routes.info}/:id/:week`]}
						component={ProgramTemplateInfoRoutes}
						exact
					/>
					<PrivateRoute
						path={[`/${routes.programs}/edit/:clientId/:id`, `/${routes.programs}/edit/:clientId/:id/:week`]}
						component={ProgramInfo}
						exact
					/>
					<PrivateRoute
						path={[`/${routes.newProgram}/${routes.week}/:week`, `/${routes.newProgram}`]}
						component={NewProgramRoutes}
						exact
					/>
					<PrivateRoute path={[`/${routes.clients}`]} component={ClientsRoutes} exact />
					<PrivateRoute path={`/${routes.coaches}`} component={CoachesRoutes} validOnlyFor={UserRole.ADMIN} exact />
					<PrivateRoute path={`/${routes.newCoach}`} component={NewCoachPage} validOnlyFor={UserRole.ADMIN} exact />
					<PrivateRoute
						path={`/${routes.coaches}/${routes.info}/:id`}
						component={CoachInfo}
						validOnlyFor={UserRole.ADMIN}
						exact
					/>
					<PrivateRoute path={`/${routes.clients}/${routes.profile}/:id`} component={ProfileClientPage} exact />
					<PrivateRoute
						path={`/${routes.clients}/${routes.profile}/:id/${routes.habitTracking}`}
						component={HabitTrackingPage}
						exact
					/>
					<PrivateRoute
						path={`/${routes.clients}/${routes.profile}/:id/${routes.progressLogs}`}
						component={ProgressLogsPage}
						exact
					/>
					<PrivateRoute path={[`/${routes.billing}`]} component={BillingRoutes} exact />
					<Redirect from="*" to="/" />
				</Switch>
			</MainLayout>
		</Switch>
	);
};

export default RootRoutes;
