import React from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
	const { pathname } = useLocation();

	React.useEffect(() => {
		requestAnimationFrame(() => {
			window.scrollTo(0, 0);
		});
	}, [pathname]);

	return null;
}

export default ScrollToTop;
