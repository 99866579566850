import React from 'react';
import { forwardRef, Input, InputProps } from '@chakra-ui/react';

const OnlyNumbersInput = forwardRef<InputProps, 'input'>((props, ref) => (
	<Input
		{...props}
		ref={ref}
		onKeyPress={(event) => {
			if (!/[0-9]/.exec(event.key)) {
				event.preventDefault();
			}
		}}
	/>
));

export default OnlyNumbersInput;
