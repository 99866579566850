import React from 'react';
import { Box } from '@chakra-ui/react';

import { SIDE_BAR_WIDTH } from 'routes/constants';

import BottombarContent from './BottombarContent';
import SidebarContent from './SidebarContent';
interface Props {
	onClose: () => void;
	isOpen: boolean;
	variant?: 'drawer' | 'sidebar';
}

const Sidebar = ({ isOpen, variant, onClose }: Props) =>
	variant === 'sidebar' ? (
		<Box
			position="fixed"
			left={0}
			p={6}
			w={`${SIDE_BAR_WIDTH}px`}
			overflow="hidden"
			top={0}
			h="100%"
			zIndex="3"
			bgColor="#323234"
			borderRight="2px"
			borderRightColor="gray.50">
			<SidebarContent onClick={onClose} />
		</Box>
	) : (
		// <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
		// 	<DrawerOverlay>
		// 		<DrawerContent>
		// 			<DrawerCloseButton />
		// 			<DrawerBody>
		// 				<SidebarContent onClick={onClose} />
		// 			</DrawerBody>
		// 		</DrawerContent>
		// 	</DrawerOverlay>
		// </Drawer>
		<Box
			overflow="hidden"
			position="fixed"
			bottom="0"
			width="100vw"
			bgColor="white"
			borderTop="2px"
			borderTopColor="gray.50"
			zIndex="3">
			<BottombarContent onClick={onClose} />
		</Box>
	);

export default Sidebar;
