import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import { signOut } from 'features/auth/store/actions';
import authReducer from 'features/auth/store/reducer';
import programReducer from 'features/programs/store/reducer';
import { history } from 'routes/constants';
import ApiService from 'shared/services/api.service';

const rootReducer = combineReducers({
	auth: authReducer,
	program: programReducer,
	router: connectRouter(history),
	[ApiService.reducerPath]: ApiService.reducer,
});

const resetRootReducer = (state: any, action: PayloadAction) => {
	if (signOut.match(action)) {
		state = undefined;
	}
	return rootReducer(state, action);
};

export default resetRootReducer;
