import { createReducer } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { decodeUserToken } from 'features/auth/helpers';
import StorageService from 'shared/services/storage.service';

import * as actions from '../actions';

import type { PayloadUserToken } from 'features/auth/types';

interface AuthState {
	isSignout: boolean;
	userToken: string | null;
	payloadData: PayloadUserToken | null;
	avatar: string | null;
}

const persistConfig: PersistConfig<any> = {
	key: 'auth',
	version: 1,
	storage: StorageService,
	timeout: 0,
	blacklist: ['isSignout'],
};

const initialState: AuthState = {
	isSignout: false,
	userToken: null,
	payloadData: null,
	avatar: null,
};

const authReducer = createReducer(initialState, (builder) => {
	builder.addCase(actions.signOut, (state) => {
		state.isSignout = true;
		state.userToken = null;
		state.payloadData = null;
	});
	builder.addCase(actions.setCredentials, (state, action) => {
		const { token, avatar } = action.payload;
		state.isSignout = false;
		state.payloadData = decodeUserToken(token);
		state.userToken = token;
		state.avatar = avatar;
	});
	builder.addCase(actions.setCredentialsGoogle, (state, action) => {
		const { credential } = action.payload;
		state.isSignout = false;
		state.payloadData = decodeUserToken(credential);
		state.userToken = credential;
	});
	builder.addCase(actions.setAvatar, (state, action) => {
		const { avatar } = action.payload;
		state.avatar = avatar;
	});
});

const authPersitReducer = persistReducer(persistConfig, authReducer) as typeof authReducer;

export default authPersitReducer;
