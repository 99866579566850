import React from 'react';
import { Alert, AlertIcon, AlertProps, CloseButton, Collapse } from '@chakra-ui/react';

type Props = AlertProps & { children: React.ReactText };

function AlertErrorMessage({ children, ...rest }: Props) {
	const [showError, setShowError] = React.useState(!!children);
	const handleClose = () => setShowError(false);

	return (
		<Collapse in={showError}>
			<Alert status="error" mt={[5, 6, 7]} {...rest}>
				<AlertIcon />
				{children}
				<CloseButton position="absolute" right="8px" top="8px" onClick={handleClose} />
			</Alert>
		</Collapse>
	);
}

export default AlertErrorMessage;
