const colors = {
	brand: {
		40: '#fdffe6',
		50: '#f8ff99',
		100: '#f5ff66',
		150: '#F6FF7F',
		200: '#f1ff33',
		300: '#f0ff1a',
		400: '#EDFF00',
		500: '#a7b300',
		600: '#becc00',
		700: '#a7b300',
		800: '#8f9900',
		900: '#474d00',
	},
	gray: {
		50: '#F0F2F4',
		100: '#D6DAE1',
		150: '#D3DCE6',
		200: '#BBC3CE',
		300: '#A0ABBA',
		400: '#8693A7',
		500: '#6B7C94',
		600: '#566376',
		650: '#8492A6',
		700: '#404A59',
		800: '#2B323B',
		900: '#15191E',
	},
	red: {
		50: '#FDE7E7',
		100: '#FABDBD',
		200: '#F79292',
		300: '#F36868',
		400: '#F03D3D',
		500: '#ED1212',
		600: '#BD0F0F',
		700: '#8E0B0B',
		800: '#5F0707',
		900: '#2F0404',
	},
	yellow: {
		50: '#FEF7E6',
		100: '#FDE8B9',
		200: '#FCD98D',
		300: '#FBCA60',
		400: '#FABB33',
		500: '#F9AC06',
		600: '#C78A05',
		700: '#956704',
		800: '#644502',
		900: '#322201',
	},
	blue: {
		50: '#E6F0FF',
		100: '#B9D5FE',
		200: '#8CBBFD',
		300: '#5FA0FC',
		400: '#3185FB',
		500: '#046AFB',
		600: '#0355C9',
		700: '#034096',
		800: '#022B64',
		900: '#011532',
	},
};

export default colors;
