import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { signOut } from 'features/auth/store/actions';
import { DEFAULT_ERROR_MESSAGE } from 'shared/constants';
import ToastService from 'shared/services/toast.service';

import type { RootState } from '../types';

/**
 * @description Show a toast!
 */
const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
	// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
	if (isRejectedWithValue(action) && action.type.startsWith('api')) {
		const aborted = action.meta?.aborted;
		if (!aborted) {
			// console.warn('We got a rejected action!', action);
			const state = api.getState() as RootState;
			const userToken = state?.auth?.userToken;
			const status = action?.payload?.originalStatus || action?.payload?.status || 0;
			const description = action?.payload?.data?.message || (status >= 500 && DEFAULT_ERROR_MESSAGE);
			const title = status >= 500 ? 'Something went wrong!' : '';

			if ((!!userToken || (!userToken && status >= 500)) && status >= 400 && description) {
				ToastService({ status: 'error', title, description, isClosable: true });
			}
			if (status === 401 && userToken) {
				api.dispatch(signOut());
			}
		}
	}

	return next(action);
};

export default rtkQueryErrorLogger;
