import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { matchPath, NavLinkProps } from 'react-router-dom';

import BillingLogo from 'assets/images/navbar/billing.png';
import ClientsLogo from 'assets/images/navbar/clients.png';
import PackagesLogo from 'assets/images/navbar/packages.png';
import ScheduleLogo from 'assets/images/navbar/schedule.png';
import ServicesLogo from 'assets/images/navbar/services.png';
import WorkoutLogo from 'assets/images/navbar/workout.png';
import { selectUserRole } from 'features/auth/store/selectors';
import { routes } from 'routes/constants';
import { UserRole } from 'shared/constants';
import { useAppSelector } from 'shared/store/hooks';

import BottomNavItem from './BottomNavItem';

import type { IconType } from 'react-icons';

interface SideBarItemProps extends NavLinkProps {
	title: string;
	icon: IconType;
	roles: Array<UserRole>;
}

type Props = {
	onClick: () => void;
};

const LATERAL_BAR: Array<SideBarItemProps> = [
	// {
	// 	title: 'Dashboard',
	// 	icon: UilHome,
	// 	to: `/${routes.dashboard}`,
	// 	roles: [UserRole.COACH, UserRole.ADMIN],
	// },
	{
		title: 'Workout Builder',
		icon: WorkoutLogo,
		to: `/${routes.programs}`,
		isActive: (match, location) => !!match || !!matchPath(location.pathname, { path: [`/${routes.newProgram}`] }),
		roles: [UserRole.COACH, UserRole.ADMIN],
	},
	{
		title: 'Services',
		icon: ServicesLogo,
		to: `/${routes.services}`,
		roles: [UserRole.COACH, UserRole.ADMIN],
	},
	{
		title: 'Packages',
		icon: PackagesLogo,
		to: `/${routes.packages}`,
		roles: [UserRole.COACH, UserRole.ADMIN],
	},
	{ title: 'Clients', icon: ClientsLogo, to: `/${routes.clients}`, roles: [UserRole.COACH, UserRole.ADMIN] },
	{ title: 'Billing', icon: BillingLogo, to: `/${routes.billing}`, roles: [UserRole.ADMIN] },
	{
		title: 'Schedule',
		icon: ScheduleLogo,
		to: `/${routes.schedule}`,
		roles: [UserRole.COACH, UserRole.ADMIN],
	},
];

const BottombarContent = ({ onClick }: Props) => {
	const role = useAppSelector(selectUserRole);

	return (
		<HStack flex="1" w={['auto', 'full']} overflowX="scroll">
			{React.Children.toArray(
				LATERAL_BAR.filter((item) => role && item.roles.includes(role)).map((item) => (
					<Box style={{ width: '16.67%', justifyContent: 'center' }}>
						<BottomNavItem key={item.title} {...item} onClick={onClick}>
							{item.title}
						</BottomNavItem>
					</Box>
				)),
			)}
		</HStack>
	);
};

export default BottombarContent;
