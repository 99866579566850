import { createApi, FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import config from 'shared/constants/config';

import type { RootState } from 'shared/store';

const baseQuery = fetchBaseQuery({
	baseUrl: config.api.baseURL,
	prepareHeaders: (headers, { getState }) => {
		// By default, if we have a token in the store, let's use that for authenticated requests
		const token = (getState() as RootState).auth.userToken;
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

const staggeredBaseQueryWithBailOut = retry(
	async (args: string | FetchArgs, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions);
		const status = (result.error as any)?.originalStatus || result.error?.status || 0;
		// bail out of re-tries immediately if unauthorized,
		// because we know successive re-retries would be redundant
		if (status === 401) {
			retry.fail(result.error);
		}

		return result;
	},
	{
		maxRetries: 1,
	},
);

const api = createApi({
	baseQuery: staggeredBaseQueryWithBailOut,
	endpoints: () => ({}),
});

export default api;
