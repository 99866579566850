import { createEntityAdapter } from '@reduxjs/toolkit';

import type { ExerciseState } from 'features/programs/types';

const exercisesAdapter = createEntityAdapter<ExerciseState>({
	selectId: (exercise) => exercise.templateId,
	sortComparer: (a, b) => a.title?.localeCompare(b.title),
});

export default exercisesAdapter;
