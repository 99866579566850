const Input = {
	baseStyle: {
		field: {
			fontFamily: 'secondary',
		},
	},
	defaultProps: {
		size: 'lg',
	},
	sizes: {
		lg: {
			field: {
				h: 16,
			},
		},
		md: {
			field: {
				h: 14,
			},
		},
	},
};

export default Input;
